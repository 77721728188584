<template>
  <div :class="{ ImportComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }" v-if="ready">
    <v-container fluid
                 :class="{ export: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
      <v-row>
        <v-col align-self="center" align="center" style="color: white">
          <div :class="{ 'my-10': true }">
            <div class="title" style="color: orange">Warning</div>
            <div>
              To import you must use the <strong>password</strong> that is used to perform the export, you must keep in mind that the import process will <strong>replace</strong> your current identities
            </div>
            <div class="mt-10">
              <v-text-field v-model="pass" type="password" outlined rounded :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" label="password" messages="Must be at least 6 characters"></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="save" block large rounded class="my-2" :dark="$vuetify.theme.dark"
                 :light="!$vuetify.theme.dark" color="green" :disabled="pass.length < 6">Import
          </v-btn>
          <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark"
                 color="black">Cancel
          </v-btn>
        </v-col>
      </v-row>
      <input type="file" ref="recovery" hidden @change="uploadRecovery">
    </v-container>
  </div>
</template>

<script>
import './_ImportComponent.scss'
import CryptoJS from 'crypto-js'
import moment from 'moment'

export default {
  name: 'ImportComponent',
  data () {
    return {
      pass: '',
      ready: false
    }
  },
  mounted () {
    this.$store.state.auth = true
  },
  watch: {
    authStatus: function (value) {
      if (value) {
        this.ready = true
      }
    }
  },
  computed: {
    authStatus () {
      return this.$store.state.authStatus
    }
  },
  methods: {
    uploadRecovery (event) {
      this.$store.state.loading = true
      try {
        const file = event.target.files[0]
        const fileReader = new FileReader()
        fileReader.addEventListener('load', async () => {
          try {
            const result = fileReader.result
            console.log(result)
            const base64 = result.split(',')
            const encrypted = CryptoJS.enc.Base64.parse(base64[1]).toString(CryptoJS.enc.Utf8)
            const decrypted = CryptoJS.AES.decrypt(encrypted, this.pass)
            const raw = decrypted.toString(CryptoJS.enc.Utf8)
            const data = JSON.parse(raw)
            console.log(data)
            this.$store.state.iD = data.iD
            this.$store.state.identities = data.identities
            console.log(this.$store.state)
            this.$store.state.events.push({ date: moment().unix(), action: 'import' })
            this.$store.commit('save')
            this.$store.commit('snackbar', {
              color: 'success',
              message: 'import_success'
            })
            this.close()
          } catch (e) {
            console.log(e.toString())
            this.$store.commit('snackbar', {
              color: 'warning',
              message: 'import_failed'
            })
            this.$refs.recovery.value = null
            this.$store.state.loading = false
          }
        })
        fileReader.readAsDataURL(file)
      } catch (e) {
        console.log(e.toString())
        this.$store.commit('snackbar', {
          color: 'warning',
          message: 'import_failed'
        })
        this.$refs.recovery.value = null
        this.$store.state.loading = false
      }
    },
    async save () {
      this.$refs.recovery.click()
    },
    close () {
      this.$store.state.import = false
      this.$store.state.loading = false
    }
  }
}
</script>
