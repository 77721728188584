<template>
    <div class="LoadingComponent">
        <v-progress-circular v-model="loading" indeterminate width="20" color="white" size="200"></v-progress-circular>
    </div>
</template>

<script>
import './_LoadingComponent.scss'
export default {
  name: 'LoadingComponent',
  data () {
    return {
      loading: 100
    }
  }
}
</script>
