<template>
  <div :class="{SandboxView: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}">
    <div class="loading">
      <v-progress-circular v-show="loading" class="slide-in-bck-center" indeterminate width="20" size="200" color="white" :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark"></v-progress-circular>
    </div>
    <component :is="app" />
    </div>
</template>

<script>
import './_SandboxView.scss'

export default {
  name: 'SandboxView',
  components: {
  },
  data () {
    return {
      loading: false,
      app: null
    }
  },
  async mounted () {
    this.loading = true
    this.app = await this.componentLoader()
    console.log('run app')
    this.loading = false
  },
  methods: {
    async componentLoader (url) {
      // const component = await import(/* webpackIgnore: true */ 'http://localhost:8080/apps/sambax-app.umd.js')
      return await this.externalComponent('http://localhost:8080/apps/sambax-app.umd.js')
    },
    async externalComponent (url) {
      const name = url.split('/').reverse()[0].match(/^(.*?)\.umd/)[1]

      if (window[name]) return window[name]

      window[name] = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.async = true
        script.addEventListener('load', () => {
          resolve(window[name])
        })
        script.addEventListener('error', () => {
          reject(new Error(`Error loading ${url}`))
        })
        script.src = url
        document.head.appendChild(script)
      })

      return window[name]
    }
  }
}
</script>
