import QRCodeStyling from 'qr-code-styling'
import moment from 'moment/moment'
import CryptoJS from 'crypto-js'
import ColorThief from 'colorthief'

export default {
  data () {
    return {
      qr: {
      }
    }
  },
  computed: {
  },
  methods: {
    async getColors (image) {
      const rgbToHex = (r, g, b) => '#' + [r, g, b].map(x => {
        const hex = x.toString(16)
        return hex.length === 1 ? '0' + hex : hex
      }).join('')

      const colorThief = new ColorThief()
      const img = await new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = () => {
          resolve(img)
        }
        img.crossOrigin = 'anonymous'
        img.src = image
        img.onerror = reject
      })
      const color = colorThief.getColor(img)
      return rgbToHex(color[0], color[1], color[2])
    },
    async generateQR (code, logo) {
      const corner = await this.getColors(logo)
      const qrcode = new QRCodeStyling({
        width: 300,
        height: 300,
        margin: 4,
        data: code,
        image: logo,
        dotsOptions: {
          color: '#10395D',
          type: 'rounded'
        },
        qrOptions: {
          errorCorrectionLevel: 'H'
        },
        cornersSquareOptions: {
          type: 'extra-rounded',
          color: corner
        },
        backgroundOptions: {
          color: '#ffffff'
        },
        imageOptions: {
          imageSize: 0.5,
          crossOrigin: 'anonymous',
          margin: 3
        }
      })

      await qrcode._canvasDrawingPromise
      return qrcode._canvas.getCanvas().toDataURL('image/png')
    },
    async decode (data) {
      try {
        this.$store.state.loading = true
        const [version, type, expire, token, hash] = data.split(':')
        console.log(version, type, expire, token, hash)
        if (version === 'iD') {
          if (moment().unix() > parseFloat(expire)) {
            throw new Error('Request expired')
          }
          if (type === 'challenge') {
            const challenge = await this.getChallenge(hash)
            challenge.iD = this.$store.state.current ? this.$store.state.current : this.$store.state.iD
            this.$store.state.challenge = challenge
            // this.openWallet()
            // const signedTx = await this.signMessage(token)
            // await this.putChallenge(this.wallet.identity.iD, hash, signedTx)
            this.$store.state.events.push({ date: moment().unix(), action: 'challenge' })
            this.$store.commit('save')
          }
          if (type === 'signature') {
            const signature = await this.getSignature(hash)
            signature.iD = this.$store.state.current ? this.$store.state.current : this.$store.state.iD
            this.$store.state.signature = signature
          }
          if (type === 'message') {
            const signature = await this.getSignature(hash)
            signature.iD = this.$store.state.current ? this.$store.state.current : this.$store.state.iD
            this.$store.state.signature = signature
          }
          if (type === 'wallet') {
            let key = ''
            await Array.from(hash).forEach((value, index) => {
              if (index % 2 === 0) {
                key += value
              }
            })
            const encrypted = CryptoJS.enc.Base64.parse(token).toString(CryptoJS.enc.Utf8)
            const decrypted = CryptoJS.AES.decrypt(encrypted, key)
            const phrase = decrypted.toString(CryptoJS.enc.Utf8)
            const wallet = await this.recoveryWallet(phrase)
            const identity = {
              iD: wallet.address,
              path: "m/44'/161803'/0'/0/0",
              name: 'Recovered',
              photo: null,
              layout: Math.floor(Math.random() * 25) + 1,
              type: 'Personal',
              wallet: true,
              edited: true,
              hd: phrase.indexOf(' ') > 0,
              secret: phrase.indexOf(' ') > 0 ? wallet.phrase.join(' ') : wallet.privateKey
            }
            this.$store.state.identities.push(identity)
            this.$store.state.events.push({ date: moment().unix(), action: 'import_wallet' })
            this.$store.commit('save')
            this.$store.commit('snackbar', {
              color: 'success',
              message: 'wallet_recovered'
            })
          }
        }
      } catch (e) {
        this.$store.state.loading = false
        this.$store.commit('snackbar', {
          color: 'error',
          message: e.message
        })
      } finally {
        this.$store.state.loading = false
      }
    }
  }
}
