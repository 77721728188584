<template>
    <div :class="{ ChallengeComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container fluid :class="{ signature: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="type">{{ $t('events.challenge') }}</div>
              <div class="detail">
                {{ $t('challenge.required_validate_identity') }}
              </div>
            </v-col>
          </v-row>
          <v-row style="height: calc(100vh - 550px);">
            <v-col align-self="center" align="center">
              <v-btn fab :class="{ important: true, heartbeat: true }" height="128px" width="128px">
                <div :class="{ important: true, wave: true, ping: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }"></div>
                <v-icon color="white" size="64px">mdi-draw-pen</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div v-if="challenge.origin" class="originContainer">
                <div class="originTitle">
                  {{ $t('general.requested_by') }}
                </div>
                <div class="origin">
                  {{ normalizeOrigin(challenge.origin) }}
                </div>
              </div>
              <div class="btn--check">
                <div class="account">
                  {{ getIdentityName(challenge.iD) }}
                  <div class="iD">
                    ...{{ challenge.iD.substr(-10, 10) }}
                  </div>
                </div>
                <v-btn @click="approveChallenge(challenge)" elevation="0" color="green lighten-4" class="rounded-0 rounded-l" height="64px" style="border-bottom-left-radius: 15px !important;">
                  <v-icon color="green">mdi-check</v-icon>
                </v-btn>
                <v-btn @click="rejectChallenge(challenge)" elevation="0" color="red lighten-4" class="rounded-0 rounded-r" height="64px" style="border-bottom-right-radius: 15px !important;">
                  <v-icon color="red">mdi-window-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      <div :class="{ icon: true, 'heartbeat': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-icon size="48" @click="close" :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">mdi-close</v-icon>
      </div>
    </div>
</template>

<script>
import './_ChallengeComponent.scss'
import signature from '@/mixins/signature'
import api from '@/mixins/api'
import wallet from '@/mixins/wallet'
export default {
  name: 'ChallengeComponent',
  data () {
    return {
      challenge: null
    }
  },
  created () {
    this.challenge = this.$store.state.challenge
  },
  mixins: [signature, api, wallet],
  methods: {
    close () {
      this.$store.state.challenge = null
    }
  }
}
</script>
