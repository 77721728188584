<template>
    <div :class="{ CreateComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container :class="{ edit: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
          <v-row>
            <v-col align-self="center" align="center" class="white--text text-uppercase">
              <div class="mb-5">
              {{  $t('create.select_token') }}
              </div>
              <div>
                <v-select :items="$store.state.networks" item-value="chainId" item-text="name" outlined rounded dense v-model="network" label="Network" style="max-width: 500px; font-size: 0.8rem" />
                <v-text-field v-model="filter" label="filter" outlined rounded class="" dense style="max-width: 500px; font-size: 0.8rem" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div style="display: flex; flex-flow: wrap; justify-content: center;">
                <div v-for="(token, index) in show" v-bind:key="index">
                <v-btn color="white" class="ma-3" fab x-large @click="addToken(token)">
                  <v-img :src="token.logoURI.includes('http') ? token.logoURI : 'https://4good.network/' + token.logoURI" contain width="48px" height="48px" />
                </v-btn>
                  <div class="caption">{{ token.symbol }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" color="black">
                {{ $t('buttons.cancel') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_CreateComponent.scss'
export default {
  name: 'CreateComponent',
  data () {
    return {
      filter: '',
      network: 846000,
      show: [],
      tokens: [],
      requests: null
    }
  },
  watch: {
    filter: function () {
      this.filterTokens()
    },
    network: function () {
      this.filterTokens()
    },
    pending: function (value) {
      if (value.state === 'success') {
        this.processAddToken(value)
      }
    }
  },
  async mounted () {
    this.$store.state.loading = true
    this.tokens = (await this.$axios.get('/finances/tokens')).data.tokens
    this.filterTokens()
    this.$store.state.loading = false
  },
  computed: {
    pending () {
      return this.$store.state.pending
    }
  },
  methods: {
    async processAddToken (signature) {
      this.$store.state.loading = true
      const address = this.requests
      address.message.signedTx = signature.signed.message
      await this.$axios.put('/network/wallets/addresses/' + this.$store.state.iD, {
        address: address
      })
      setTimeout(() => {
        this.$store.commit('load')
      }, 1000)
      this.close()
    },
    async addToken (token) {
      this.$store.state.loading = true
      const request = (await this.$axios.post('/network/wallets/addresses/' + this.$store.state.iD, {
        path: this.$store.state.networks.find(network => network.chainId === this.network).path,
        network: this.network,
        contract: token.address
      })).data
      this.requests = request

      if (this.$store.state.signer) {
        const tx = this.filterAllowTags(request)
        const signer = this.$store.state.signer
        if (this.$store.state.mode === 'Ledger') {
          this.$store.commit('requestLedgerSign', { request: request, pending: request.message })
        }
        try {
          const signedTx = await signer.signTransaction(this.parseBigNumber(tx))
          const signature = {
            signed: {
              message: signedTx
            }
          }
          this.$store.state.auth = false
          await this.processAddToken(signature)
        } catch (e) {
          this.$store.state.auth = false
          this.$store.state.loading = false
          this.step--
        }
        return
      }

      const signature = (await this.$axios.post('/identification/signatures', {
        iD: this.$store.state.iD,
        requests: request
      })).data
      this.$store.state.loading = false
      this.$store.state.pending = request.message
      this.$store.state.auth = signature
    },
    filterTokens () {
      if (this.filter.length < 3) {
        this.show = this.tokens.filter(token => token.chainId === this.network && !this.$store.state.accounts.find(account => account.token.address === token.address)).slice(0, 8)
        return
      }
      this.show = this.tokens.filter(token => !this.$store.state.accounts.find(account => account.token.address === token.address) && token.chainId === this.network && token.name.toLowerCase().includes(this.filter.toLowerCase())).slice(0, 8)
    },
    close () {
      this.$store.state.create = false
      this.$store.state.loading = false
    }
  }
}
</script>
