<template>
  <div :class="{SignaturesView: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}">
    <div :class="{ 'font-weight-bold': true, 'title': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
      {{ $t('signatures.my_pending_signatures') }}
    </div>
      <v-container fluid>
        <v-row dense>
          <v-col align-self="center" align="center">
            <div v-if="$store.state.signatures.length === 0" class="">
                  <div>
                    <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/relax.light.svg')" width="80%"  />
                    <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/relax.dark.svg')" width="80%"  />
                  </div>
              {{ $t('signatures.nothing_to_sign') }}
            </div>
            <v-alert v-for="(signature, key) in $store.state.signatures" border="bottom" v-bind:key="key" :class="[getSignatureRequest(signature.requests), 'signature']">
              <div class="content" @click="showSignature(signature)">
                <div class="account">
                  {{ getIdentityName(signature.iD) }}
                  <div class="iD">
                    ...{{ signature.iD.substr(-10, 10) }}
                  </div>
                </div>
                <div class="type">
                  {{ $t('events.' + getSignatureRequest(signature.requests)) }}
                </div>
                <div class="btn--check">
                  <v-btn @click.stop="approve(signature)" elevation="0" color="green lighten-4" class="rounded-0" height="128px">
                    <v-icon color="green">mdi-check</v-icon>
                  </v-btn>
                  <v-btn @click.stop="reject(signature)" elevation="0" color="red lighten-4" class="rounded-0 rounded-r" height="128px">
                    <v-icon color="red">mdi-window-close</v-icon>
                  </v-btn>
                </div>
                <div v-if="getAmount(signature.requests) > 0" class="amountContainer">
                  <div class="amountPriceTitle">
                    {{ $t('general.price') }}
                  </div>
                  <div class="amountPrice">
                    {{ getAmount(signature.requests) | numFormat('0.0[000]') }}
                  </div>
                  <div class="amountPriceType">
                    {{ getAmountToken(signature.requests) }}
                  </div>
                </div>
                <div v-if="getAPTAAmount(signature.requests) > 0" class="amountFeeContainer">
                  <div class="amountFeeTitle">
                    {{ $t('general.fee') }}
                  </div>
                  <div class="amountFee">
                   {{ getAPTAAmount(signature.requests) | numFormat('0.0[000]') }}
                  </div>
                  <div class="amountFeeType">
                    APTA
                  </div>
                </div>
                <div v-if="signature.origin" class="originContainer">
                  <div class="originTitle">
                    {{ $t('general.requested_by') }}
                  </div>
                  <div class="origin">
                    {{ normalizeOrigin(signature.origin) }}
                  </div>
                </div>
                <div class="detail">
                  <div class="message" v-if="getSignatureRequest(signature.requests) === 'message'">
                    <div style="font-size: 0.8rem; line-height: 100%; font-weight: bolder">{{ signature.requests.message.text }}</div>
                  </div>
                  <div class="alias" v-if="getSignatureRequest(signature.requests) === 'alias'">
                    <div style="font-size: 0.6rem; line-height: 100%">{{ $t('general.name') }}</div>
                    <div style="font-size: 0.8rem; line-height: 100%; font-weight: bolder">{{ signature.requests.alias.alias }}</div>
                  </div>
                  <div class="pay" v-if="getSignatureRequest(signature.requests) === 'pay'">
                    <div style="font-size: 0.8rem; line-height: 100%; font-weight: bolder">{{ signature.requests.pay.payData.title }}</div>
                    <div style="font-size: 0.6rem; line-height: 100%">{{ signature.requests.pay.payData.description }}</div>
                  </div>
                  <div class="swap" v-if="getSignatureRequest(signature.requests) === 'swap'">
                    <div style="font-size: 0.6rem; line-height: 100%">{{ $t('signatures.will_receive') }}</div>
                    <div style="font-size: 0.8rem; line-height: 100%; font-weight: bolder">{{ getBuyAmount(signature.requests) | numFormat('0.0[000]') }} <span style="font-size: 0.6rem">{{ getBuyAmountToken(signature.requests) }}</span></div>
                  </div>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_SignaturesView.scss'
import signature from '@/mixins/signature'
import api from '@/mixins/api'
export default {
  name: 'SignaturesView',
  mounted () {
    this.$store.commit('loadSignatures')
  },
  mixins: [signature, api],
  methods: {
    showSignature (signature) {
      this.$store.state.signature = signature
    }
  }
}
</script>
