<template>
    <div class="CameraComponent">
      <div></div>
      <div class="container-scan">
        <div class="barcode-scanner--area--container">
          <div class="square surround-cover">
            <div class="barcode-scanner--area--outer surround-cover">
              <div class="barcode-scanner--area--inner"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import './_CameraComponent.scss'

const REAR_CAMERA = 'rear'
const FRONT_CAMERA = 'front'

export default {
  name: 'CameraComponent',
  data () {
    return {
      data: null,
      manual: false,
      camera: {
        paused: false,
        enabled: true,
        facingMode: 'auto'
      }
    }
  },
  methods: {
    onDecode (content) {
      if (content.length > 0) {
        this.data = content
        this.scanDisabled()
        this.processCode()
        return
      }
      this.scanDisabled()
      this.scan()
    },
    async onInit (promise) {
      try {
        await promise
      } catch (error) {
        let message = ''
        if (error.name === 'NotAllowedError') {
          message = 'ERROR: you need to grant camera access permission'
        } else if (error.name === 'NotFoundError') {
          message = 'ERROR: no camera on this device'
        } else if (error.name === 'NotSupportedError') {
          message = 'ERROR: secure context required (HTTPS, localhost)'
        } else if (error.name === 'NotReadableError') {
          message = 'ERROR: is the camera already in use?'
        } else if (error.name === 'OverconstrainedError') {
          message = 'ERROR: installed cameras are not suitable'
          this.camera.facingMode = 'auto'
        } else if (error.name === 'StreamApiNotSupportedError') {
          message = 'ERROR: Stream API is not supported in this browser'
        }
        console.log(message)
      }
    },
    scan () {
      this.camera.paused = false
      this.camera.enabled = true
      this.camera.facingMode = FRONT_CAMERA
    },
    changeCamera () {
      this.camera.facingMode = this.camera.facingMode === FRONT_CAMERA ? REAR_CAMERA : FRONT_CAMERA
    },
    scanDisabled () {
      this.camera.enabled = false
      this.camera.paused = true
      this.camera.facingMode = null
    },
    closeCamera () {
      this.scanDisabled()
      this.$emit('close')
    },
    processCode () {
      this.$emit('close', this.data)
    }
  }
}
</script>
