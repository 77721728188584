<template>
  <v-app>
    <div id="version">{{ version }}</div>
    <v-main>
      <router-view/>
    </v-main>
    <v-btn v-if="!$store.state.app.run" fixed top right small icon @click="setThemeMode(!$vuetify.theme.dark)"><v-icon>mdi-brightness-6</v-icon></v-btn>
    <v-snackbar bottom style="z-index: 1000" min-width="100vw" min-height="64px"  v-model="$store.state.snackbar.enabled" :color="$store.state.snackbar.color">
      <div align="center">
        {{ $t('snackbar.' + $store.state.snackbar.message) }}
      </div>
    </v-snackbar>
    <transition name="fade">
      <LoadingComponent v-show="$store.state.loading" />
    </transition>
  </v-app>
</template>

<script>

import '@/animations/animations.scss'
import LoadingComponent from '@/components/LoadingComponent/LoadingComponent'
import 'vuetify/dist/vuetify.css'

export default {
  name: 'App',
  components: { LoadingComponent },
  data () {
    return {
      version: 'beta'
    }
  },
  mounted () {
    /*    this.$store.state.tenant = {
      app: true,
      name: 'Sambax',
      logo: require('@/assets/logos/sambax.svg'),
      button: {
        icon: require('@/assets/logos/sambax_icon.svg'),
        name: 'Sambax',
        app: 'sambax-app'
      },
      theme: {
        primary: '#ff0033',
        secondary: '#bbcbd3'
      }
    } */

    const matchDarkMode = window.matchMedia('(prefers-color-scheme: dark)')
    this.$vuetify.theme.dark = matchDarkMode.matches
    this.setThemeMode(this.$vuetify.theme.dark)
    matchDarkMode.addEventListener('change', () => {
      this.$vuetify.theme.dark = matchDarkMode.matches
      this.setThemeMode(this.$vuetify.theme.dark)
    })
    this.version = 'v' + process.env.VUE_APP_VERSION + '-beta'
  },
  methods: {
    setThemeMode (style) {
      this.$vuetify.theme.dark = style
    }
  }
}
</script>

<style>
:root {
  --app-primary-light-color: #8db9e4;
  --app-primary-dark-color: #3d597b;
  --app-secondary-light-color: unset;
  --app-secondary-dark-color: unset;
}
#version {
  position: absolute;
  top: 3px;
  right: 3px;
  font-size: 0.6rem;
  text-transform: uppercase;
}

#app.theme--dark {
  background: #3d597b;
}
#app.theme--light {
  background: #8db9e4;
}

.backgroundOff {
  background: unset !important;
}

.row--dense {
  margin: 0 !important;
}

.primary--text {
  color: #3d597b !important;
  caret-color: #3d597b !important;
}

.row--dense > .col, .row--dense > [class*=col-] {
  padding: 0 !important;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
