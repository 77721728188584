import { initializeApp } from 'firebase/app'
import { getMessaging } from 'firebase/messaging'

const firebaseConfig = {
  apiKey: 'AIzaSyCQSsoXLkQ1oypd4PrxMOsbEhS5XgYVFdA',
  authDomain: 'identity4good.firebaseapp.com',
  databaseURL: 'https://identity4good.firebaseio.com',
  projectId: 'identity4good',
  storageBucket: 'identity4good.appspot.com',
  messagingSenderId: '1052796377875',
  appId: '1:1052796377875:web:73015b1f42e718e9d6d8e6',
  measurementId: 'G-1C0RHJPTLX'
}

const firebaseApp = initializeApp(firebaseConfig)
export default getMessaging(firebaseApp)
