<template>
  <div :class="{ ExportComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }" v-if="ready">
    <v-container fluid
                 :class="{ export: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
      <v-row>
        <v-col align-self="center" align="center" style="color: white">
          <div :class="{ 'my-10': true }">
            <div class="title" style="color: orange">Warning</div>
            <div>
              An <strong>encrypted export</strong> of all your identities will be generated, which can only be recovered with the <strong>password</strong> assigned to you
            </div>
            <div class="mt-10">
              <v-text-field v-model="pass" type="password" outlined rounded :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" label="password" messages="Must be at least 6 characters"></v-text-field>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="save" block large rounded class="my-2" :dark="$vuetify.theme.dark"
                 :light="!$vuetify.theme.dark" color="green" :disabled="pass.length < 6">Export
          </v-btn>
          <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark"
                 color="black">Cancel
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_ExportComponent.scss'
import moment from 'moment'

export default {
  name: 'ExportComponent',
  data () {
    return {
      pass: '',
      ready: false
    }
  },
  mounted () {
    this.$store.state.auth = true
  },
  watch: {
    authStatus: function (value) {
      if (value) {
        this.ready = true
      }
    }
  },
  computed: {
    authStatus () {
      return this.$store.state.authStatus
    }
  },
  methods: {
    async save () {
      this.$store.state.loading = true
      this.$store.state.events.push({ date: moment().unix(), action: 'export' })
      this.$store.commit('save')
      this.$store.commit('snackbar', {
        color: 'success',
        message: 'export_success'
      })
      this.close()
    },
    close () {
      this.$store.state.export = false
      this.$store.state.loading = false
    }
  }
}
</script>
