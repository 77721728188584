<template>
    <div class="LoadingView">
      <v-container fluid class="fill-height slide-out-bck-center" style="animation-delay: 2.5s">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="slide-in-bottom overline" style="animation-delay: 0s">
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="slide-in-bottom overline" style="animation-delay: 1s">
              <v-img max-width="300px" width="50%" :src="require('@/assets/icon.svg')"  />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="slide-in-bottom overline" style="animation-delay: 1.2s">
              <v-img max-width="150px" :src="require('@/assets/empowered.svg')"  />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import './_LoadingView.scss'
export default {
  name: 'LoadingView',
  async mounted () {
    console.log('loading...')
    console.log('query', this.$route.query)
    if (this.$route.query.token) {
      this.$store.state.preLogin = this.$route.query.token
    }
    if (this.$route.query.app) {
      this.$store.state.preApp = this.$route.query.app
    }
    setTimeout(() => {
      this.$router.push({ name: 'login' })
    }, 3000)
  }
}
</script>
