<template>
    <div :class="{ SignatureComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container fluid :class="{ signature: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="type">{{ $t('events.' + getSignatureRequest(signature.requests)) }}</div>
              <div class="detail">
                <div class="message" v-if="getSignatureRequest(signature.requests) === 'message'">
                  <div style="font-size: 1rem; line-height: 100%; font-weight: bolder">{{ signature.requests.message.text }}</div>
                </div>
                <div class="alias" v-if="getSignatureRequest(signature.requests) === 'alias'">
                  <div style="font-size: 0.8rem; line-height: 100%">name</div>
                  <div style="font-size: 1rem; line-height: 100%; font-weight: bolder">{{ signature.requests.alias.alias }}</div>
                </div>
                <div class="pay" v-if="getSignatureRequest(signature.requests) === 'pay'">
                  <div style="font-size: 1rem; line-height: 100%; font-weight: bolder">{{ signature.requests.pay.payData.title }}</div>
                  <div style="font-size: 0.8rem; line-height: 100%">{{ signature.requests.pay.payData.description }}</div>
                </div>
                <div class="swap" v-if="getSignatureRequest(signature.requests) === 'swap'">
                  <div style="font-size: 1rem; line-height: 100%">You will receive</div>
                  <div style="font-size: 1.2rem; line-height: 100%; font-weight: bolder">{{ getBuyAmount(signature.requests) | numFormat('0.0[000]') }} <span style="font-size: 0.6rem">{{ getBuyAmountToken(signature.requests) }}</span></div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row style="height: calc(100vh - 550px);">
            <v-col v-if="getAmount(signature.requests) > 0" align-self="center" align="center" style="background-color: #00000015">
              <div class="amountContainer">
                <div class="amountPriceTitle">
                  Price
                </div>
                <span class="amountPrice">
                  {{ getAmount(signature.requests) | numFormat('0.0[000]') }}
                </span>
                <span class="amountPriceType">
                  {{ getAmountToken(signature.requests) }}
                </span>
              </div>
            </v-col>
            <v-col v-if="getAPTAAmount(signature.requests) > 0" align-self="center" align="center" style="background-color: #00000015">
              <div class="amountFeeContainer">
                <div class="amountFeeTitle">
                  {{ $t('general.fee') }}
                </div>
                <span class="amountFee">
                  {{ getAPTAAmount(signature.requests) | numFormat('0.0[000]') }}
                </span>
                <span class="amountFeeType">
                  APTA
                </span>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div v-if="signature.origin" class="originContainer">
                <div class="originTitle">
                  {{ $t('general.requested_by') }}
                </div>
                <div class="origin">
                  {{ normalizeOrigin(signature.origin) }}
                </div>
              </div>
              <div class="btn--check">
                <div class="account">
                  {{ getIdentityName(signature.iD) }}
                  <div class="iD">
                    ...{{ signature.iD.substr(-10, 10) }}
                  </div>
                </div>
                <v-btn @click="approve(signature)" elevation="0" color="green lighten-4" class="rounded-0 rounded-l" height="64px" style="border-bottom-left-radius: 15px !important;">
                  <v-icon color="green">mdi-check</v-icon>
                </v-btn>
                <v-btn @click="reject(signature)" elevation="0" color="red lighten-4" class="rounded-0 rounded-r" height="64px" style="border-bottom-right-radius: 15px !important;">
                  <v-icon color="red">mdi-window-close</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>
      <div :class="{ icon: true, 'heartbeat': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-icon size="48" @click="close" :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">mdi-close</v-icon>
      </div>
    </div>
</template>

<script>
import './_SignatureComponent.scss'
import signature from '@/mixins/signature'
import api from '@/mixins/api'
export default {
  name: 'SignatureComponent',
  data () {
    return {
      signature: null
    }
  },
  created () {
    this.signature = this.$store.state.signature
  },
  mixins: [signature, api],
  methods: {
    close () {
      this.$store.state.signature = null
    }
  }
}
</script>
