import { BigNumber, utils } from 'ethers'

export default {
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    parseBigNumber (tx) {
      const numerics = ['value', 'gas', 'gasLimit', 'gasPrice', 'maxPriorityFeePerGas']

      return Object.keys(tx)
        .reduce((obj, key) => {
          if (numerics.includes(key)) {
            obj[key] = BigNumber.from(tx[key])
          } else {
            obj[key] = tx[key]
          }
          return obj
        }, {})
    },
    filterAllowTags (tx) {
      const allowed = ['to', 'from', 'nonce', 'data', 'value', 'gasLimit', 'gasPrice', 'maxFeePerGas', 'maxPriorityFeePerGas', 'chainId', 'type', 'accessList']

      return Object.keys(tx)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = tx[key]
          return obj
        }, {})
    },
    getIdentityName (iD) {
      const identity = this.$store.state.identities.find(item => item.iD === iD)
      return identity.name
    },
    normalizeOrigin (value) {
      value = value.replace('http://', '')
      value = value.replace('https://', '')
      return value
    },
    getAmount (requests) {
      let amount = '0'
      for (const request in requests) {
        if (requests[request].payAmount) {
          amount = requests[request].payAmount
        }
        if (requests[request].sellAmount) {
          amount = requests[request].sellAmount
        }
      }
      return utils.formatEther(amount)
    },
    getBuyAmount (requests) {
      let amount = '0'
      for (const request in requests) {
        if (requests[request].buyAmount) {
          amount = requests[request].buyAmount
        }
      }
      return utils.formatEther(amount)
    },
    getAmountToken (requests) {
      for (const request in requests) {
        if (requests[request].payAmount) {
          return requests[request].payToken
        }
        if (requests[request].sellAmount) {
          return requests[request].sellToken
        }
      }
    },
    getBuyAmountToken (requests) {
      for (const request in requests) {
        if (requests[request].buyAmount) {
          return requests[request].buyToken
        }
      }
    },
    getAPTAAmount (requests) {
      const supported = ['alias', 'fee']
      let amount = BigNumber.from('0')
      for (const request in requests) {
        if (supported.includes(request)) {
          if (requests[request].chainId === 846000) {
            amount = amount.add(requests[request].value)
          }
        }
      }
      return utils.formatEther(amount)
    },
    getSignatureRequest (requests) {
      console.log(requests)
      const supported = ['alias', 'swap', 'pay', 'message', 'document', 'mint', 'merchant', 'device', 'pool', 'mortgage', 'lending', 'deposit']
      for (const request in requests) {
        if (supported.includes(request)) {
          return request
        }
      }
      return 'unsupported'
    }
  }
}
