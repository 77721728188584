<template>
    <div :class="{ QRCodeComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container :class="{ edit: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
          <v-row>
            <v-col align-self="center" align="center">
              <v-progress-circular v-if="!qr" size="150" width="20" indeterminate />
              <v-img v-if="qr" :src="qr" aspect-ratio="1" width="250px" height="250px"  max-height="50vw" max-width="50vw" contain style="border-radius: 16px; z-index: 10"></v-img>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" color="black">
                {{ $t('buttons.cancel') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_QRCodeComponent.scss'
import qr from '@/mixins/qr'
export default {
  name: 'QRCodeComponent',
  data () {
    return {
      qr: false
    }
  },
  async mounted () {
    this.qr = await this.generateQR(this.$store.state.qr.text, this.$store.state.qr.logo)
  },
  mixins: [qr],
  methods: {
    close () {
      this.$store.state.qr = false
      this.$store.state.loading = false
    }
  }
}
</script>
