<template>
    <div :class="{ 'HomeView': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark } ">
      <v-container fluid class="pa-0">
        <v-row dense :class="{ header: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
          <v-col align-self="center" align="center" class="pa-0 mb-3">
            <div class="crystal"></div>
            <div class="slide-in-top" style="animation-delay: 0s">
              <v-img max-width="300px" height="64px" :src="require('@/assets/logo_light_alt.svg')"  />
            </div>
            <div class="mt-2 balanceTitle">
              Your Balance
            </div>
            <div>
              <span class="balance" v-bind:render="$store.state.render">{{ getBalance | numFormat('$ 0,0.[00]') }}</span> <span class="currency">USD</span>
            </div>
          </v-col>
        </v-row>
        <v-row dense :class="{ content: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
          <v-col align-self="center" align="center" class="slide-in-top pa-0">
            <div id="wave-container" v-if="false">
              <div id="wave" :class="{ 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
              </div>
            </div>
            <router-view class="fade-in-fwd" />
          </v-col>
        </v-row>
      </v-container>
      <div v-if="$store.state.mode === 'Metamask'" class="modeIcon">
        <v-img width="24px" height="24px" :src="require('@/assets/metamask-icon.svg')"  />
      </div>
      <transition name="fade">
        <BarComponent />
      </transition>
      <transition name="fade">
        <CameraComponent v-if="$store.state.camera" />
      </transition>
      <transition name="fade">
        <QRCodeComponent v-if="$store.state.qr" />
      </transition>
      <transition name="fade">
        <SandboxComponent v-if="$store.state.app.run" />
      </transition>
      <transition name="fade">
        <TransactionHistoryComponent v-if="$store.state.transactionHistory" />
      </transition>
      <transition name="fade">
        <SendTokenComponent v-if="$store.state.sendToken" />
      </transition>
      <transition name="fade">
        <ReceiveTokenComponent v-if="$store.state.receiveToken" />
      </transition>
      <transition name="fade">
        <SignatureComponent v-if="$store.state.signature" />
      </transition>
      <transition name="fade">
        <ChallengeComponent v-if="$store.state.challenge" />
      </transition>
      <transition name="fade">
        <div class="overlay" v-if="$store.state.overlay"></div>
      </transition>
      <transition name="fade">
        <CustomPassportComponent v-if="$store.state.edit" />
      </transition>
      <transition name="fade">
        <CreateComponent v-if="$store.state.create" />
      </transition>
      <transition name="fade">
        <CreateAssetComponent v-if="$store.state.createAsset" />
      </transition>
      <transition name="fade">
        <ChangePinComponent v-if="$store.state.changePin" />
      </transition>
      <transition name="fade">
        <ResetComponent v-if="$store.state.reset" />
      </transition>
      <transition name="fade">
        <ExportComponent v-if="$store.state.export" />
      </transition>
      <transition name="fade">
        <ImportComponent v-if="$store.state.import" />
      </transition>
      <transition name="fade">
        <EventsComponent v-if="$store.state.eventsList" />
      </transition>
      <transition name="fade">
        <AuthenticationComponent v-if="$store.state.auth" />
      </transition>
    </div>
</template>

<script>
import './_HomeView.scss'
import BarComponent from '@/components/BarComponent/BarComponent'
import CameraComponent from '@/components/CameraComponent/CameraComponent'
import api from '@/mixins/api'
import SignatureComponent from '@/components/SignatureComponent/SignatureComponent'
import AuthenticationComponent from '@/components/AuthenticationComponent/AuthenticationComponent'
import CustomPassportComponent from '@/components/CustomPassportComponent/CustomPassportComponent'
import CreateComponent from '@/components/CreateComponent/CreateComponent'
import ChangePinComponent from '@/components/ChangePinComponent/ChangePinComponent'
import ResetComponent from '@/components/ResetComponent/ResetComponent'
import ExportComponent from '@/components/ExportComponent/ExportComponent'
import ImportComponent from '@/components/ImportComponent/ImportComponent'
import EventsComponent from '@/components/EventsComponent/EventsComponent'
import wallet from '@/mixins/wallet'
import qr from '@/mixins/qr'
import ChallengeComponent from '@/components/ChallengeComponent/ChallengeComponent'
import QRCodeComponent from '@/components/QRCodeComponent/QRCodeComponent'
import SendTokenComponent from '@/components/SendTokenComponent/SendTokenComponent'
import { getToken, onMessage } from 'firebase/messaging'
import TransactionHistoryComponent from '@/components/TransactionHistoryComponent/TransactionHistoryComponent'
import SandboxComponent from '@/components/SandboxComponent/SandboxComponent'
import ReceiveTokenComponent from '@/components/ReceiveTokenComponent/ReceiveTokenComponent'
import CreateAssetComponent from '@/components/CreateAssetComponent/CreateAssetComponent'

export default {
  name: 'HomeView',
  components: { CreateAssetComponent, ReceiveTokenComponent, SandboxComponent, TransactionHistoryComponent, SendTokenComponent, QRCodeComponent, ChallengeComponent, EventsComponent, ImportComponent, ExportComponent, ResetComponent, ChangePinComponent, CreateComponent, CustomPassportComponent, AuthenticationComponent, SignatureComponent, CameraComponent, BarComponent },
  async mounted () {
    this.$store.commit('load')
    Notification.requestPermission().then(() => {
      getToken(this.$messaging, { vapidKey: 'BJlInps6N3wj9xjdrgEKCgcm30UJv_XgtHQ25M2VR8WvbbBLectyDa3I-s1BtqI0FEGKKoWtA0fLqht8x-2XLE0' }).then(token => {
        console.log('token', token)
        this.registerNotification(this.$store.state.iD, token)
        onMessage(this.$messaging, (message) => {
          if (message.data.iD === this.$store.state.iD) {
            this.$store.commit('snackbar', {
              color: 'cyan',
              message: 'transaction_received'
            })
            this.$store.commit('load')
          }
        })
      })
    })
  },
  updated () {
    if (!this.$store.state.iD) {
      this.$router.push({
        name: 'loading'
      })
    }
  },
  watch: {
    jobs: function (value) {
      console.log(value)
      if (value.state === 'completed') {
        this.$store.commit('snackbar', {
          color: 'success',
          message: 'job_completed'
        })
      }
      if (value.state === 'error') {
        this.$store.commit('snackbar', {
          color: 'warning',
          message: 'job_failed'
        })
      }
    }
  },
  mixins: [api, wallet, qr],
  methods: {
  },
  computed: {
    jobs () {
      return this.$store.state.jobs
    },
    getBalance () {
      let balance = 0
      for (const account of this.$store.state.accounts) {
        balance += account.balance * account.price
      }
      return !isNaN(balance) ? balance : 0
    }
  }
}
</script>
