import Vue from 'vue'
import Vuex from 'vuex'
import { BigNumber, utils } from 'ethers'
import { create } from 'ipfs-http-client'

Vue.use(Vuex)

const updateStatusJob = async (state, payload) => {
  try {
    const job = (await window.axios.get('/jobs/' + payload.job + '/wait')).data
    const currentJob = state.jobs.find(item => item.id === job.hash)
    currentJob.created = job.created
    currentJob.updated = job.updated
    currentJob.state = job.state
    if (job.state !== 'completed' || job.state !== 'error') {
      updateStatusJob(state, payload)
    }
  } catch (e) {
    updateStatusJob(state, payload)
  }
}

export default new Vuex.Store({
  state: {
    snackbar: {
      enabled: false,
      color: 'warning',
      message: ''
    },
    app: {
      run: false,
      full: false,
      currentComponent: false,
      data: {
        account: false,
        dni: false,
        card: false
      }
    },
    preLogin: false,
    preApp: false,
    balance: 0,
    jobs: [],
    tenant: false,
    create: false,
    createAsset: false,
    loading: false,
    auth: false,
    pending: false,
    render: 0,
    loadingAccounts: false,
    loadingAssets: false,
    assetsPermit: null,
    qr: false,
    transactionHistory: false,
    sendToken: false,
    receiveToken: false,
    // iD: 'iD4G<1462c6eed639f7a324fb3b893bb1e330f8045a9d96022fbb517f48de1ee51fcece893572af370ab2d8346ed2087bcbb5',
    iD: false,
    session: null,
    mode: 'iD4Good',
    signer: false,
    assets: [],
    accounts: [],
    storeFileProgress: {},
    networks: [
      { name: '4GoodNetwork', chainId: 846000, path: "m/44'/161803'/0'/0/0" },
      { name: 'Ethereum', chainId: 1, path: "m/44'/60'/0'/0'/0" }
    ],
    stables: {
      T4G: 'USD',
      'T4G-USD': 'USD',
      'T4G-CLP': 'CLP',
      'T4G-BRL': 'BRL',
      'T4G-CAD': 'CAD'
    }
  },
  getters: {
    storeFile: (state) => async (file) => {
      const ipfs = create('https://api.asset4good.com')
      const { path, size } = await ipfs.add(file, { progress: (progress) => { state.storeFileProgress[file.name] = progress } })
      delete state.storeFileProgress[file.name]
      return { cid: path, size: size }
    },
    isTenantApp: state => {
      if (state.tenant) {
        return state.tenant.app
      }
    },
    haveTenantButton: state => {
      if (state.tenant) {
        return state.tenant.button
      }
    },
    getTenantApp: state => {
      if (state.tenant) {
        return state.tenant.app
      }
    }
  },
  mutations: {
    waitJob: (state, payload) => {
      state.jobs.push({
        id: payload.job
      })
      updateStatusJob(state, payload)
    },
    setColors: (state, payload) => {
      const doc = document.documentElement
      doc.style.setProperty('--app-primary-light-color', payload.primaryLightColor)
      doc.style.setProperty('--app-primary-dark-color', payload.primaryDarkColor)
    },
    resetColors: (state, payload) => {
      const doc = document.documentElement
      doc.style.setProperty('--app-primary-light-color', '#8db9e4')
      doc.style.setProperty('--app-primary-dark-color', '#3d597b')
    },
    setComponent: (state, payload) => {
      state.app.currentComponent = payload.component
    },
    snackbar: (state, payload) => {
      state.snackbar.color = payload.color
      state.snackbar.message = payload.message
      state.snackbar.enabled = true
    },
    requestSign: async (state, payload) => {
      state.loading = true
      const signature = (await window.axios.post('/identification/signatures', {
        iD: state.iD,
        requests: payload.request
      })).data
      state.pending = payload.pending
      state.auth = signature
      state.loading = false
    },
    requestLedgerSign: async (state, payload) => {
      state.loading = true
      state.pending = payload.pending
      state.auth = true
      state.loading = false
    },
    requestAuth: async (state, payload) => {
      state.loading = true
      const signature = (await window.axios.post('/identification/authorization', {
        iD: state.iD,
        message: payload.message,
        length: 6
      })).data
      state.pending = { code: signature.confirm }
      state.auth = signature
      state.loading = false
    },
    loadAssets: async (state, payload) => {
      state.loadingAssets = true
      const assetsTypes = (await window.axios.get('/sandbox')).data
      try {
        if (!state.session) {
          await payload.router.push({ name: 'login' })
          return
        }
        const balance = (await window.axios.post('/network/wallets/assets/' + state.iD + '/balance', {
          session: state.session.session
        })).data
        console.log(balance)
        state.assets = []
        state.assets.push({
          key: 3,
          type: 'nft',
          name: 'NFT',
          icon: assetsTypes[3].icon,
          balance: balance[3],
          network: 846000,
          startup: 'Click to <strong>create</strong> your first NFT',
          deleted: false,
          app: assetsTypes[3].app
        })
        state.assets.push({
          key: 4,
          type: 'sft',
          name: 'SFT',
          icon: assetsTypes[4].icon,
          balance: balance[4],
          network: 846000,
          startup: 'Click to <strong>create</strong> your first SFT',
          deleted: false,
          app: assetsTypes[4].app
        })
        state.assets.push({
          key: 100,
          type: 'sambax',
          name: 'Sambax',
          icon: require('@/assets/apps/app-sambax.png'),
          balance: balance[100],
          network: 846000,
          startup: 'Click to <strong>create</strong> your Sambax Account',
          deleted: true,
          app: assetsTypes[100].app
        })
        state.loadingAssets = false
        state.assetsPermit = true
        if (state.preApp) {
          state.assets.forEach(item => {
            if (item.type === state.preApp) {
              state.app.run = item.app
              state.preApp = false
            }
          })
        }
      } catch (e) {
        console.log(e)
        state.assetsPermit = false
      }
    },
    load: async (state, payload) => {
      state.loadingAccounts = true
      state.accounts = []
      state.accounts.push({
        address: state.iD,
        contract: 'iD4G<0000000000000000000000000000000000000000000000000000000000000000',
        native: true,
        network: 846000,
        balance: false,
        deleted: false
      })
      const addresses = (await window.axios.get('/network/wallets/addresses/' + state.iD)).data.reverse()
      for (const address of addresses) {
        state.accounts.push({
          address: address.address,
          contract: address.contract,
          native: !address.contract,
          network: address.network,
          balance: false,
          deleted: true
        })
      }
      for (const account of state.accounts) {
        window.axios.get('/finances/tokens/' + account.contract)
          .then((result) => {
            account.token = result.data
            window.axios.get('/finances/market/' + (state.stables[account.token.symbol] ? state.stables[account.token.symbol] : account.token.symbol) + '/price')
              .then((result) => {
                account.price = result.data.price
                state.render++
              })
            window.axios.get('/network/addresses/' + state.iD + '/balance' + (!account.native ? '/' + account.contract : ''))
              .then((result) => {
                const balance = BigNumber.from(result.data)
                account.balance = utils.formatUnits(balance, account.token.decimals)
                state.render++
                state.loadingAccounts = false
              })
          })
      }
    }
  },
  actions: {
  },
  modules: {
  }
})
