<template>
  <div :class="{ EventsComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
    <v-container fluid
                 :class="{ event: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
      <v-row>
        <v-col align-self="center" align="center" style="color: white">
          <div class="title">{{ $t('general.events') }}</div>
          <div>
            <v-container fluid class="px-0">
              <v-row dense v-for="(event, index) in listEvents" v-bind:key="index">
                <v-col class="px-0">
                  <v-alert class="ma-0 my-1" dense style="background-color: #00000055" dark align="center" border="left">
                    <div class="titleEvent" align="right">{{ $t('events.' + event.action) }}</div>
                    <div class="dateEvent" align="left">{{ getFormatDate(event.date) }}</div>
                  </v-alert>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark"
                 color="black">{{ $t('buttons.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_EventsComponent.scss'
import tools from '@/mixins/tools'
export default {
  name: 'EventsComponent',
  data () {
    return {
      listEvents: []
    }
  },
  mixins: [tools],
  mounted () {
    const events = [...this.$store.state.events].reverse()
    this.listEvents = events.slice(0, 10)
  },
  methods: {
    close () {
      this.$store.state.eventsList = false
    }
  }
}
</script>
