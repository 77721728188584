<template>
  <div :class="{ 'AuthenticationComponent': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'slide-out-bck-center': next }">
    <v-container fluid class="fill-height">
      <v-row>
        <v-col align-self="center" align="center">
          <div class="slide-in-bottom overline" style="animation-delay: 0s">
            <v-img max-width="300px" width="50%" :src="require('@/assets/logo_light.svg')"  />
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center" class="white--text text-uppercase">
          {{  $t('auth.need_confirmation') }}
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <div class="slide-in-bottom overline" style="animation-delay: 0.3s" v-if="!success && !fail">
            <v-progress-circular size="150" width="20" indeterminate />
            <div v-if="$store.state.mode === 'Ledger'" class="mt-5">
              <div>Check you Ledger</div>
              <v-img v-if="!$vuetify.theme.dark" :src="require('@/assets/ledger_dark.svg')" contain height="30px" width="80px" aspect-ratio="1" />
              <v-img v-if="$vuetify.theme.dark" :src="require('@/assets/ledger_light.svg')" contain height="30px" width="80px" aspect-ratio="1" />
            </div>
            <div v-if="$store.state.pending.code" class="white--text text-uppercase pt-5">
            Confirm Code
              <div class="title">
                {{ $store.state.pending.code }}
              </div>
            </div>
          </div>
          <div class="slide-in-bottom overline" style="animation-delay: 0.3s" v-if="success">
            <v-btn fab class="fingerprint heartbeat" height="128px" width="128px" color="green">
              <div :class="{fingerprint: true, wave: true, ping: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}"></div>
              <v-icon color="white" size="96px">mdi-check</v-icon>
            </v-btn>
          </div>
          <div class="slide-in-bottom overline" style="animation-delay: 0.3s" v-if="fail">
            <v-btn fab class="fingerprint heartbeat" height="128px" width="128px" color="orange">
              <div :class="{fingerprint: true, wave: true, ping: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}"></div>
              <v-icon color="white" size="96px">mdi-close</v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col align-self="center" align="center">
          <div class="mb-5 font-weight-bold" style="color: red" v-if="error">
            {{ $t('login.wrong_pin') }}
          </div>
          <div class="slide-in-bottom overline" style="animation-delay: 0.3s">
            <div>
              <v-btn class="my-3 px-10" rounded elevation="0" large dark @click="cancel">{{  $t('buttons.cancel') }}</v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_AuthenticationComponent.scss'
export default {
  name: 'AuthenticationComponent',
  data () {
    return {
      activePin: false,
      next: false,
      success: false,
      fail: false,
      pin: '',
      error: false,
      fingerprintAvailable: false
    }
  },
  mounted () {
    if (this.$store.state.mode === 'Ledger') {
      return
    }
    this.wait()
  },
  beforeDestroy () {
  },
  methods: {
    wait () {
      this.$axios.get('/identification/signatures/' + this.$store.state.auth.hash + '/wait').then((result) => {
        if (result.data.state === 'scanning') {
          this.wait()
          return
        }
        if (result.data.state === 'waiting') {
          this.wait()
          return
        }
        if (result.data.state === 'success') {
          this.$store.state.pending = result.data
          this.success = true
          this.finish()
          return
        }
        this.$store.state.pending = { state: 'failed' }
        this.fail = true
        this.finish()
      }).catch(() => {
        this.$store.state.pending = { state: 'failed' }
        this.fail = true
        this.finish()
      })
    },
    finish () {
      setTimeout(() => {
        this.next = true
      }, 2500)
      setTimeout(() => {
        this.cancel()
      }, 3000)
    },
    cancel () {
      this.$store.state.authStatus = false
      this.$store.state.auth = false
      this.$store.state.export = false
      this.$store.state.import = false
      this.$store.state.changePin = false
      this.$store.state.reset = false
    }
  }
}
</script>
