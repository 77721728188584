<template>
    <div :class="{ ReceiveTokenComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container :class="{ edit: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="container-steps">
                <div v-if="step === 1" :class="{ 'slide-out-left': step !== 1, 'slide-in-right': step === 1, 'step': true }">
                  <div class="white--text text-uppercase mb-5">
                    Select Currency
                  </div>
                  <v-text-field v-model="filter" label="filter" outlined rounded class="my-2" dense style="max-width: 300px; font-size: 0.8rem" />
                  <div class="mt-5" style="display: flex; flex-flow: wrap; justify-content: center;">
                    <div v-for="(account, index) in show" v-bind:key="index">
                      <v-btn color="white" class="ma-3" fab x-large @click="selectToken(account)">
                        <v-img :src="account.token.logoURI.includes('http') ? account.token.logoURI : 'https://4good.network/' + account.token.logoURI" contain width="48px" height="48px" />
                      </v-btn>
                      <div class="caption">{{ account.token.symbol }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="step === 2" :class="{ 'slide-out-left': step !== 2, 'slide-in-right': step === 2, 'step': true }">
                  <div class="white--text text-uppercase mb-5" v-if="$store.state.receiveToken.token">
                    Receive
                    <v-img class="mt-2" :src="$store.state.receiveToken.token.token.logoURI.includes('http') ? $store.state.receiveToken.token.token.logoURI : 'https://4good.network/' + $store.state.receiveToken.token.token.logoURI" contain width="48px" height="48px" />
                  </div>
                  <v-text-field v-if="$store.state.receiveToken.token" v-model="amount" label="Amount" outlined rounded class="" dense style="max-width: 500px; font-size: 0.8rem" :suffix="$store.state.receiveToken.token.token.symbol" />
                  <div class="d-flex" style="max-width: 500px; justify-content: space-between">
                    <div class="balance-container" v-if="$store.state.receiveToken.token">
                      <div class="balance-name">Balance</div>
                      <span class="balance-value">{{ $store.state.receiveToken.token.balance | numFormat('$ 0.00[00]')  }}</span> <span class="currency">{{ $store.state.receiveToken.token.token.symbol }}</span>
                    </div>
                    <div class="cost-container">
                    </div>
                  </div>
                </div>
                <div v-if="step === 3" :class="{ 'slide-out-left': step !== 3, 'slide-in-right': step === 3, 'step': true }">
                  <div class="white--text text-uppercase mb-5" v-if="$store.state.receiveToken.token">
                    Receive Pending
                    <v-img class="mt-2" :src="$store.state.receiveToken.token.token.logoURI.includes('http') ? $store.state.receiveToken.token.token.logoURI : 'https://4good.network/' + $store.state.receiveToken.token.token.logoURI" contain width="48px" height="48px" />
                    <div class="mt-5">
                      <v-img v-if="signature.qr" :src="signature.qr" aspect-ratio="1" width="250px" height="250px"  max-height="50vw" max-width="50vw" contain style="border-radius: 16px; z-index: 10"></v-img>
                      <div class="mt-2">
                        Share this QR to be used with iD4Good
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div v-if="step === 3">
                <v-btn rounded class="mx-2" @click="share"><v-icon>mdi-share</v-icon></v-btn>
                <v-btn rounded class="mx-2" @click="download"><v-icon>mdi-download</v-icon></v-btn>
              </div>
              <div>
                <v-btn :disabled="loading || !(amount > 0)" v-if="step === 2" class="my-5 px-10" rounded elevation="0" style="background-color: #00ab66 !important;" large @click="processTransaction">{{ $t('buttons.create') }}</v-btn>
              </div>
              <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" color="black">
                {{ $t('buttons.close') }}</v-btn>
              <div style="max-width: 100%">
                <v-progress-linear indeterminate v-if="loading" color="white" height="10" rounded />
              </div>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_ReceiveTokenComponent.scss'
import { BigNumber, utils } from 'ethers'
import wallet from '@/mixins/wallet'
export default {
  name: 'ReceiveTokenComponent',
  data () {
    return {
      step: 1,
      filter: '',
      show: [],
      origin: '',
      amount: '',
      receipt: false,
      error: false,
      shareData: {},
      tx: {},
      loading: false,
      signature: false,
      cost: BigNumber.from(0)
    }
  },
  watch: {
    pending: function (value) {
      if (value.state === 'success') {
        this.finishTransaction(value)
      }
      if (value.state === 'failed') {
        this.step--
      }
    },
    amount: function (value) {
      if (value.length === 0) {
        return
      }
      const amount = utils.parseUnits(value, this.$store.state.receiveToken.token.token.decimals)
      if (amount.gt(BigNumber.from('0'))) {
        this.generateTransaction(amount)
      }
    },
    filter: function () {
      this.filterTokens()
    }
  },
  async mounted () {
    if (this.$store.state.receiveToken.token) {
      this.nextStep()
    }
    this.filterTokens()
  },
  mixins: [wallet],
  computed: {
    auth () {
      return this.$store.state.auth
    },
    pending () {
      return this.$store.state.pending
    }
  },
  methods: {
    async download () {
      const a = document.createElement('a')
      a.href = this.signature.qr
      a.download = 'qr.png'
      a.click()
      this.$store.commit('snackbar', {
        color: 'success',
        message: 'qr_downloaded'
      })
    },
    async share () {
      if (navigator.canShare(this.data)) {
        await navigator.share(this.data)
        return
      }
      this.$store.commit('snackbar', {
        color: 'warning',
        message: 'share_unsupported'
      })
    },
    async processTransaction () {
      this.$store.state.loading = true
      const request = {
        transfer: this.tx
      }
      request.transfer.payAmount = utils.parseUnits(this.amount, this.$store.state.receiveToken.token.token.decimals)
      request.transfer.payToken = this.$store.state.receiveToken.token.token.symbol
      request.transfer.destination = '...' + this.$store.state.receiveToken.token.address.slice(-16)
      const signature = (await this.$axios.post('/identification/signatures', {
        requests: request,
        offgrid: true,
        trigger: true
      })).data
      this.$store.state.loading = false
      this.$store.state.pending = request.transfer
      this.signature = signature
      const blob = await (await fetch(this.signature.qr)).blob()
      const file = new File([blob], 'qr.png', { type: blob.type })
      this.data = {
        title: 'Transfer Request',
        text: 'Scan with your iD4Good',
        files: [file]
      }
      this.$store.state.loading = false
      this.nextStep()
    },
    async generateTransaction (amount) {
      this.error = false
      this.loading = true
      try {
        console.log(this.$store.state.receiveToken.token)
        const from = this.$store.state.receiveToken.token.address
        const to = this.$store.state.receiveToken.token.address
        this.tx = await this.getTransaction(from, to, amount, !this.$store.state.receiveToken.token.native ? this.$store.state.receiveToken.token.token.address : false)
        delete this.tx.from
        delete this.tx.nonce
        // this.cost = BigNumber.from(await this.estimateTransaction(this.tx))
      } catch (e) {
        this.error = e.response.data.error
      }
      this.loading = false
    },
    selectToken (account) {
      this.$store.state.receiveToken.token = account
      this.nextStep()
    },
    filterTokens () {
      const tokens = this.$store.state.accounts
      if (this.filter.length < 3) {
        this.show = tokens.slice(0, 8)
        return
      }
      this.show = tokens.filter(account => account.token.name.toLowerCase().includes(this.filter.toLowerCase())).slice(0, 8)
    },
    nextStep () {
      this.step++
    },
    close () {
      this.$store.state.receiveToken = false
      this.$store.state.loading = false
    }
  }
}
</script>
