<template>
    <div :class="{ SandboxComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
      <div :class="{ 'background-app': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }"></div>
      <v-container :class="{ app: true, 'custom-scrollbar': true, 'app-full': $store.state.app.full, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'pa-0': true }">
        <v-row>
          <v-col align-self="center" align="center" class="pa-0">
            <v-progress-circular v-if="loading" size="150" width="20" indeterminate />
            <component :is="app" />
          </v-col>
        </v-row>
      </v-container>
      <div class="logo-app">
        <v-img max-width="150px" height="32px" contain :src="require('@/assets/logo_light_alt.svg')"  />
      </div>
      <div class="close-app">
        <v-btn @click="closeApp" fab height="64px" width="64px" style="transform: translate(50%, -50%)">
          <v-icon small style="transform: translate(-50%, 50%)">mdi-close</v-icon>
        </v-btn>
      </div>
    </div>
</template>

<script>
import './_SandboxComponent.scss'

export default {
  name: 'SandboxComponent',
  data () {
    return {
      loading: false,
      app: null
    }
  },
  async mounted () {
    this.loading = true
    this.app = await this.componentLoader(this.$store.state.app.run)
    console.log('run app')
    this.loading = false
  },
  beforeDestroy () {
    this.$store.state.app = {
      run: false,
      full: false,
      currentComponent: false,
      data: {}
    }
  },
  methods: {
    closeApp () {
      this.$store.state.app.run = false
    },
    async componentLoader (url) {
      // const component = await import(/* webpackIgnore: true */ 'http://localhost:8080/apps/sambax-app.umd.js')
      return await this.externalComponent(url)
    },
    async externalComponent (url) {
      const name = url.split('/').reverse()[0].match(/^(.*?)\.umd/)[1]

      if (window[name]) return window[name]

      window[name] = new Promise((resolve, reject) => {
        const script = document.createElement('script')
        script.async = true
        script.addEventListener('load', () => {
          resolve(window[name])
        })
        script.addEventListener('error', () => {
          reject(new Error(`Error loading ${url}`))
        })
        script.src = url
        document.head.appendChild(script)
      })

      return window[name]
    }
  }
}
</script>
