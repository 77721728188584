<template>
    <div :class="{ 'BarComponent': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
      <div class="d-flex">
        <div :class="{ 'border-bar': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }" v-if="$vuetify.breakpoint.width > 900"></div>
        <v-container fluid>
          <v-row>
            <v-col align-self="center" align="center">
              <v-btn elevation="0" active-class="active" to="/home/main" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-wallet</v-icon></v-btn>
              <v-btn elevation="0" active-class="active" to="/home/signatures" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button">
                <v-badge offset-x="-2" offset-y="-2" color="green" dot bottom :value="0">
                  <v-icon>mdi-cards</v-icon>
                </v-badge>
              </v-btn>
              <v-btn elevation="0" active-class="active" to="/home/setting" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-cog</v-icon></v-btn>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
</template>

<script>
import './_BarComponent.scss'
export default {
  name: 'BarComponent'
}
</script>
