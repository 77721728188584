export default {
  data () {
    return {
      api: {
      }
    }
  },
  computed: {
  },
  methods: {
    async encodeAddress (address) {
      const result = await this.$axios.put('/network/wallets/addresses/' + address + '/encode')
      return result.data
    },
    async createWallet () {
      const result = await this.$axios.post('/network/wallets', {
      })
      return result.data
    },
    async recoveryWallet (phrase) {
      const result = await this.$axios.put('/network/wallets', {
        phrase: phrase
      })
      return result.data
    },
    async approveSignature (signature) {
      await this.$axios.put('/identification/signatures/' + signature.hash, {
        iD: signature.iD,
        signed: signature.signed
      })
    },
    async rejectSignature (signature) {
      await this.$axios.put('/identification/signatures/' + signature.hash + '/reject')
    },
    async approveMessage (signature) {
      await this.$axios.put('/identification/messages/' + signature.hash, {
        iD: signature.iD,
        signed: signature.signed
      })
    },
    async rejectMessage (signature) {
      await this.$axios.put('/identification/messages/' + signature.hash + '/reject')
    },
    async postPendingSignatures (iDs) {
      const result = await this.$axios.post('/identification/signatures/pending',
        {
          iDs: iDs
        })
      return result.data
    },
    async getChallenge (hash) {
      const result = await this.$axios.get('/identification/' + hash)
      return result.data
    },
    async getSignature (hash) {
      const result = await this.$axios.get('/identification/signatures/' + hash + '/data')
      return result.data
    },
    async postNotification (iD, token) {
      await this.$axios.post('/identification/notifications',
        {
          iD: iD,
          token: token
        })
    },
    async putRejectChallenge (hash) {
      await this.$axios.put('/identification/' + hash + '/reject')
    },
    async putChallenge (iD, hash, signedTx, hd) {
      try {
        const result = await this.$axios.put('/identification/' + hash,
          {
            iD: iD,
            signature: signedTx,
            hd: hd
          })
        return result.data
      } catch (e) {
        if (e.response.status === 404) {
          throw new Error('Challenge not found')
        }
        if (e.response.status === 419) {
          throw new Error('Challenge expired')
        }
        if (e.response.status === 403) {
          throw new Error('invalid signature')
        }
        if (e.response.status === 406) {
          throw new Error('Challenge used')
        }
        throw new Error(e.message)
      }
    }
  }
}
