<template>
    <div :class="{MainView: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}">
        <v-container fluid class="mx-0 px-0 mt-5">
          <v-row dense>
            <v-col align-self="center" align="center">
              <div class="button-slider" align="center">
                <v-btn @click="showiD" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button" style="text-transform: initial"><v-icon>iD</v-icon></v-btn>
                <v-btn @click="sendToken(false)" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-send</v-icon></v-btn>
                <v-btn @click="receiveToken(false)" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-download</v-icon></v-btn>
                <v-btn elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-swap-horizontal-bold</v-icon></v-btn>
                <v-btn @click="showTokenHistory(true)" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-history</v-icon></v-btn>
                <v-btn @click="showAssets" v-if="!$store.getters.haveTenantButton" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-cards</v-icon></v-btn>
                <v-btn v-if="$store.getters.haveTenantButton" @click="showApp" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button">
                  <v-img :src="$store.getters.haveTenantButton.icon" :alt="$store.getters.haveTenantButton.name" contain width="24px" height="24px" />
                </v-btn>
                <v-btn @click="refresh" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-refresh</v-icon></v-btn>
                <v-btn @click="switchLowAmount" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button">
                  <v-icon v-if="hiddenLowAmount">mdi-eye-off</v-icon>
                  <v-icon v-if="!hiddenLowAmount">mdi-eye</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col align-self="center" align="center" class="">
              <v-progress-linear v-if="$store.state.loadingAccounts" indeterminate style="max-width: 720px" color="#8db9e4" class="mt-1" />
              <v-expansion-panels flat class="mt-5 px-1" style="max-width: 720px">
                <v-expansion-panel
                  v-for="(account,index) in $store.state.accounts" v-bind:key="index" class="my-1 rounded-xl" v-bind:render="$store.state.render">
                  <div style="position: absolute; opacity: 0.05; width: 100%; height: 100%; filter: blur(12px)">
                    <v-img v-if="account.token" :src="account.token.logoURI" width="120%" height="120%" position="center center" style="max-width: 100%; max-height: 100%; border-radius: 24px" />
                  </div>
                  <v-expansion-panel-header v-if="hiddenLowAmount ? account.token && account.balance >= 1 : account.token" expand-icon="mdi-arrow-down-drop-circle-outline">
                    <div class="d-flex">
                      <div>
                        <v-img v-if="account.token" :src="account.token.logoURI" contain width="48px" height="48px" />
                      </div>
                      <div class="ml-5">
                        <div class="token-name mt-1">{{ account.token.name }}</div>
                        <div class="token-usd mt-1">{{ account.price | numFormat('$ 0.[00]') }} <span class="currency">USD</span></div>
                      </div>
                    </div>
                    <div align="right" class="mr-5">
                      <div class="token-name mt">{{ account.balance | numFormat('$ 0.00[00]') }}</div>
                      <div class="currency">{{ account.token.symbol }}</div>
                      <div class="token-usd mt-2">{{ (account.balance * account.price) | numFormat('$ 0.[00]') }} <span class="currency">USD</span></div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content v-if="hiddenLowAmount ? account.token && account.balance >= 1 : account.token">
                    <div class="mx-2">
                      <div>
                        <v-btn @click="showQR(account)" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile small class="rounded-lg mx-1 button-account"><v-icon>mdi-qrcode-scan</v-icon></v-btn>
                        <v-btn @click="copy(account)" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile small class="rounded-lg mx-1 button-account"><v-icon>mdi-content-copy</v-icon></v-btn>
                        <v-btn @click="sendToken(account)" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile small class="rounded-lg mx-1 button-account"><v-icon>mdi-send</v-icon></v-btn>
                        <v-btn elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile small class="rounded-lg mx-1 button-account"><v-icon>mdi-swap-horizontal-bold</v-icon></v-btn>
                        <v-btn @click="showTokenHistory(account)" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile small class="rounded-lg mx-1 button-account"><v-icon>mdi-history</v-icon></v-btn>
                        <v-btn :disabled="!account.deleted" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile small class="rounded-lg mx-1 button-account" color="red"><v-icon>mdi-close-box</v-icon></v-btn>
                      </div>
                      <div class="address-container mt-1">
                        <span class="address-name" v-if="account.address.substring(0,2) === 'iD'">iD: </span>
                        <span v-if="account.address.substring(0,2) !== 'iD'">Address: </span>
                        <span class="address-value">...{{ account.address.slice(-16) }}</span>
                      </div>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
                <v-expansion-panel
                  class="my-1 rounded-xl">
                    <v-btn height="88" @click="showCreate" fab tile block plain x-large><v-icon>mdi-plus</v-icon></v-btn>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_MainView.scss'
import moment from 'moment'
import tools from '@/mixins/tools'
export default {
  name: 'MainView',
  data () {
    return {
      lastLogin: moment().unix(),
      ready: false,
      hiddenLowAmount: false
    }
  },
  mixins: [tools],
  mounted () {
    this.$forceUpdate()
  },
  methods: {
    showAssets () {
      this.$router.push({
        name: 'assets'
      })
    },
    switchLowAmount () {
      this.hiddenLowAmount = !this.hiddenLowAmount
    },
    showApp () {
      this.$store.state.app.run = this.$store.getters.getTenantApp
    },
    refresh () {
      this.$store.commit('load')
    },
    showTokenHistory (account) {
      this.$store.state.transactionHistory = account
    },
    receiveToken (account) {
      this.$store.state.receiveToken = {
        token: account
      }
    },
    sendToken (account) {
      this.$store.state.sendToken = {
        token: account
      }
    },
    showiD () {
      this.$store.state.qr = {
        text: this.$store.state.iD,
        logo: require('@/assets/icon-iD.png')
      }
    },
    showQR (account) {
      this.$store.state.qr = {
        text: account.address,
        logo: account.token.logoURI
      }
    },
    copy (account) {
      navigator.clipboard.writeText(account.address)
      this.$store.commit('snackbar', {
        color: 'success',
        message: 'address_copy'
      })
    },
    showCreate () {
      this.$store.state.create = true
    },
    showEvents () {
      this.$store.state.eventsList = true
    }
  },
  computed: {
    scale: function () {
      return window.innerWidth * 0.8 > 500 ? 500 : window.innerWidth * 0.8
    }
  }
}
</script>
