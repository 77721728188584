<template>
    <div :class="{ CreateAssetComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container :class="{ edit: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
          <v-row>
            <v-col align-self="center" align="center" class="white--text text-uppercase">
              <div class="mb-5">
              {{  $t('create.select_asset') }}
              </div>
              <div>
                <v-text-field v-model="filter" label="filter" outlined rounded class="" dense style="max-width: 500px; font-size: 0.8rem" />
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div style="display: flex; flex-flow: wrap; justify-content: center;">
                <div v-for="(asset, index) in show" v-bind:key="index">
                <v-btn color="transparent" class="ma-3" icon height="96" width="96" @click="addToken(token)" style="background-color: transparent">
                  <v-img :src="asset.icon.includes('http') ? asset.icon : 'https://4good.network/' + asset.icon" contain width="96px" height="96px" />
                </v-btn>
                  <div class="caption">{{ asset.name }}</div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" color="black">
                {{ $t('buttons.cancel') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_CreateAssetComponent.scss'
export default {
  name: 'CreateAssetComponent',
  data () {
    return {
      filter: '',
      network: 846000,
      show: [],
      assets: [],
      requests: null
    }
  },
  watch: {
    filter: function () {
      this.filterAssets()
    },
    network: function () {
      this.filterAssets()
    },
    pending: function (value) {
      if (value.state === 'success') {
        this.processAddToken(value)
      }
    }
  },
  async mounted () {
    this.$store.state.loading = true
    const assets = (await this.$axios.get('/assets')).data
    for (const asset in assets) {
      this.assets.push(assets[asset])
    }
    this.filterAssets()
    this.$store.state.loading = false
  },
  computed: {
    pending () {
      return this.$store.state.pending
    }
  },
  methods: {
    async processAddToken (signature) {
      this.$store.state.loading = true
      const address = this.requests
      address.message.signedTx = signature.signed.message
      await this.$axios.put('/network/wallets/addresses/' + this.$store.state.iD, {
        address: address
      })
      setTimeout(() => {
        this.$store.commit('load')
      }, 1000)
      this.close()
    },
    async addToken (token) {
      this.$store.state.loading = true
      const request = (await this.$axios.post('/network/wallets/addresses/' + this.$store.state.iD, {
        path: this.$store.state.networks.find(network => network.chainId === this.network).path,
        network: this.network,
        contract: token.address
      })).data
      this.requests = request
      const signature = (await this.$axios.post('/identification/signatures', {
        iD: this.$store.state.iD,
        requests: request
      })).data
      this.$store.state.loading = false
      this.$store.state.pending = request.message
      this.$store.state.auth = signature
    },
    filterAssets () {
      const max = this.$vuetify.breakpoint.width < 400 ? 6 : this.$vuetify.breakpoint.width < 900 ? 8 : this.$vuetify.breakpoint.width < 1280 ? 16 : 32
      if (this.filter.length < 3) {
        this.show = this.assets.slice(0, max)
        return
      }
      this.show = this.assets.filter(asset => asset.name.toLowerCase().includes(this.filter.toLowerCase())).slice(0, max)
    },
    close () {
      this.$store.state.createAsset = false
      this.$store.state.loading = false
    }
  }
}
</script>
