<template>
    <div :class="{ TransactionHistoryComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container :class="{ edit: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="white--text text-uppercase mb-5">
                Transactions History
                <v-img v-if="account && account !== true" class="mt-2" :src="account.token.logoURI.includes('http') ? account.token.logoURI : 'https://4good.network/' + account.token.logoURI" contain width="48px" height="48px" />
              </div>
              <v-text-field label="Search" outlined rounded class="" dense style="max-width: 500px; font-size: 0.8rem" />
              <v-progress-circular v-if="!history" size="150" width="20" indeterminate />
              <div class="d-inline-flex mt-5" v-if="history">
                <div ref="history" class="history disable-scrollbars" @touchend="touchEnd">
                <v-expansion-panels flat class="px-1">
                  <v-expansion-panel
                    v-for="(tx,index) in show" v-bind:key="index" class="my-1 rounded-xl">
                    <v-expansion-panel-header expand-icon="mdi-arrow-down-drop-circle-outline">
                      <div class="logo-float" v-if="account === true">
                        <v-img :src="getLogo(tx)" contain width="24px" height="24px" />
                      </div>
                      <div class="d-flex">
                        <div class="d-flex align-self-center">
                          <v-btn fab small color="white">
                            <v-icon color="orange" v-if="getDirection(tx) === 'send'">mdi-arrow-top-right-thick</v-icon>
                            <v-icon color="green" v-if="getDirection(tx) === 'receive'">mdi-arrow-bottom-left-thick</v-icon>
                            <v-icon color="cyan" v-if="getDirection(tx) === 'stay'">mdi-minus-thick</v-icon>
                          </v-btn>
                        </div>
                        <div class="ml-5">
                          <div class="item-left-container">
                            <div class="item-left-name" v-if="getDirection(tx) === 'send'">To</div>
                            <div class="item-left-name" v-if="getDirection(tx) === 'receive'">From</div>
                            <div class="item-left-name" v-if="getDirection(tx) === 'stay'">Address</div>
                            <div class="item-left-value" v-if="getDirection(tx) === 'send'">...{{ getTo(tx).slice(limit) }}</div>
                            <div class="item-left-value" v-if="getDirection(tx) === 'receive'">...{{ getFrom(tx).slice(limit) }}</div>
                            <div class="item-left-value" v-if="getDirection(tx) === 'stay'">...{{ getFrom(tx).slice(limit) }}</div>
                          </div>
                          <div class="item-left-container mt-1">
                            <div class="item-left-name">Date</div>
                            <div class="item-left-value">{{ getFormatDate(tx.timestamp) }}</div>
                          </div>
                        </div>
                      </div>
                      <div align="right" class="mr-5">
                        <div class="item-right-container">
                          <div class="item-right-name">Amount</div>
                          <div class="item-right-value" style="font-size: 1.2rem; font-weight: bolder">{{ getFormatAmount(tx) | numFormat('0.00[00]') }}</div>
                          <div class="currency">{{ getSymbol(tx) }}</div>
                        </div>
                      </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="d-flex justify-space-between">
                        <div class="d-flex align-self-center" v-if="$vuetify.breakpoint.width > 500">
                          <v-img class="mt-2" :src="getLogo(tx)" contain width="48px" height="48px" />
                        </div>
                        <div class="ml-3" style="flex-grow: 1">
                          <div class="item-left-container">
                            <div class="item-left-name">Currency</div>
                            <div class="item-left-value">{{ getName(tx) }}</div>
                          </div>
                          <div class="item-left-container mt-2">
                            <div class="item-left-name">Hash</div>
                            <div class="item-left-value">...{{ tx.hash.slice(limit) }}</div>
                          </div>
                          <div class="item-left-container mt-2" v-if="detectContractFrom(tx)">
                            <div class="item-left-name">Sender</div>
                            <div class="item-left-value">...{{ tx.from.slice(limit) }}</div>
                          </div>
                        </div>
                        <div align="right">
                          <div class="item-right-container">
                            <div class="item-right-name">Nonce</div>
                            <div class="item-right-value">{{ tx.nonce }}</div>
                          </div>
                          <div class="item-right-container mt-2">
                            <div class="item-right-name">Block</div>
                            <div class="item-right-value">{{ tx.blockNumber }}</div>
                          </div>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
                <div style="display: grid; align-content: center" class="my-5 ml-5" v-if="$vuetify.breakpoint.width > 600 && show.length <= 3">
                  <v-btn fab small @click="more">
                    <v-icon>mdi-dots-horizontal</v-icon>
                  </v-btn>
                </div>
                <div style="display: grid; align-content: space-between" class="my-5 ml-5" v-if="$vuetify.breakpoint.width > 600  && show.length > 3">
                  <div>
                  <v-btn fab small @click="less">
                    <v-icon>mdi-arrow-up</v-icon>
                  </v-btn>
                  </div>
                  <div>
                  <v-btn fab small @click="more">
                    <v-icon>mdi-arrow-down</v-icon>
                  </v-btn>
                  </div>
                </div>
              </div>
              <div class="mt-2" v-if="$vuetify.breakpoint.width <= 600 && show.length <= 3">
                <v-btn fab small @click="more">
                  <v-icon>mdi-dots-horizontal</v-icon>
                </v-btn>
              </div>
              <div class="mt-2" v-if="$vuetify.breakpoint.width <= 600 && show.length > 3">
                <v-btn fab small @click="less" class="mx-2">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn fab small @click="more" class="mx-2">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-progress-linear v-if="loading" indeterminate />
              <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" color="black">
                {{ $t('buttons.close') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_TransactionHistoryComponent.scss'
import wallet from '@/mixins/wallet'
import tools from '@/mixins/tools'
import { BigNumber, utils } from 'ethers'
export default {
  name: 'TransactionHistoryComponent',
  data () {
    return {
      iD: null,
      account: null,
      history: false,
      loading: false,
      show: [],
      limit: -16,
      moreCount: 0
    }
  },
  async mounted () {
    this.iD = this.$store.state.iD
    this.account = this.$store.state.transactionHistory
    this.history = (await this.getHistory(this.account === true ? this.iD : this.account.address)).reverse()
    this.calculateHistory()
  },
  updated () {
    if (this.$vuetify.breakpoint.width < 600) {
      this.limit = -8
    } else {
      this.limit = -16
    }
  },
  mixins: [wallet, tools],
  methods: {
    getName (tx) {
      if (!tx.contract) {
        const token = this.$store.state.accounts.find(account => account.token.chainId === 846000).token
        return token.name
      }
      if (tx.contract) {
        const token = this.$store.state.accounts.find(account => account.token.address === tx.to).token
        return token.name
      }
    },
    detectContractFrom (tx) {
      if (tx.contract) {
        if (tx.contract.contract === undefined) {
          return false
        }
        return !!tx.contract.inputs._from
      }
      return false
    },
    getFrom (tx) {
      if (!tx.contract) {
        return tx.from
      }
      if (tx.contract) {
        if (tx.contract.contract === undefined) {
          return tx.from
        }
        return tx.contract.inputs._from ? tx.contract.inputs._from : tx.from
      }
    },
    getTo (tx) {
      if (!tx.contract) {
        return tx.to
      }
      if (tx.contract) {
        if (tx.contract.contract === undefined) {
          return tx.to
        }
        return tx.contract.inputs._to ? tx.contract.inputs._to : ''
      }
    },
    getLogo (tx) {
      if (!tx.contract) {
        const token = this.$store.state.accounts.find(account => account.token.chainId === 846000).token
        return token.logoURI.includes('http') ? token.logoURI : 'https://4good.network/' + token.logoURI
      }
      if (tx.contract) {
        const token = this.$store.state.accounts.find(account => account.token.address === tx.to).token
        return token.logoURI.includes('http') ? token.logoURI : 'https://4good.network/' + token.logoURI
      }
    },
    getSymbol (tx) {
      if (!tx.contract) {
        const token = this.$store.state.accounts.find(account => account.token.chainId === 846000).token
        return token.symbol
      }
      if (tx.contract) {
        const token = this.$store.state.accounts.find(account => account.token.address === tx.to).token
        return token.symbol
      }
    },
    getFormatAmount (tx) {
      if (!tx.contract) {
        const value = BigNumber.from(tx.value)
        return utils.formatEther(value)
      }
      if (tx.contract) {
        if (tx.contract.contract === undefined) {
          const value = BigNumber.from(tx.value)
          return utils.formatEther(value)
        }
        const token = this.$store.state.accounts.find(account => account.token.address === tx.to).token
        const value = BigNumber.from(tx.contract.inputs._value)
        return utils.formatUnits(value, token.decimals)
      }
    },
    getDirection (tx) {
      if (tx.to === tx.from) {
        return 'stay'
      }
      if (tx.to === this.iD) {
        return 'receive'
      }
      if (tx.from === this.iD) {
        return 'send'
      }
      if (tx.contract.inputs._to === this.iD) {
        return 'receive'
      }
      if (tx.contract.inputs._from === this.iD) {
        return 'send'
      }
    },
    calculateHistory () {
      this.show = this.history.filter(tx => {
        if (this.account === true) {
          return true
        }
        if (this.account.native) {
          if (!tx.contract) {
            return true
          }
        }
        if (!this.account.native) {
          if (!tx.contract) {
            return false
          }
          if (tx.contract.contract === undefined) {
            return false
          }
          if (!this.$store.state.accounts.find(account => account.token.address === tx.contract.contract.address)) {
            return false
          }
          if (tx.contract) {
            return true
          }
        }
        return false
      })
    },
    less () {
      const move = this.$refs.history.scrollTop - this.$vuetify.breakpoint.height * 0.5
      this.$refs.history.scrollTo({ top: move, behavior: 'smooth' })
    },
    async touchEnd () {
      console.log('end touch')
      if (this.$refs.history.scrollTop === (this.$refs.history.scrollHeight - this.$refs.history.clientHeight)) {
        await this.more()
      }
    },
    async more () {
      if (this.$refs.history.scrollTop === (this.$refs.history.scrollHeight - this.$refs.history.clientHeight)) {
        console.log('load more')
        this.loading = true
        this.moreCount++
        const more = (await this.getHistory(this.account === true ? this.iD : this.account.address, this.moreCount)).reverse()
        const current = this.history
        this.history = [...current, ...more]
        this.calculateHistory()
        this.loading = false
      }
      const move = this.$refs.history.scrollTop + this.$vuetify.breakpoint.height * 0.5
      this.$refs.history.scrollTo({
        top: move,
        behavior: 'smooth'
      })
    },
    close () {
      this.$store.state.transactionHistory = false
      this.$store.state.loading = false
    }
  }
}
</script>
