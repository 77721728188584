<template>
  <div :class="{AssetsView: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}">
    <v-container fluid class="mx-0 px-0 mt-5">
      <v-row dense>
        <v-col align-self="center" align="center">
          <div class="button-slider" align="center">
            <v-btn @click="showiD" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button" style="text-transform: initial"><v-icon>iD</v-icon></v-btn>
            <v-btn elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-swap-horizontal-bold</v-icon></v-btn>
            <v-btn @click="showAssetsHistory()" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-history</v-icon></v-btn>
            <v-btn @click="showMain" v-if="!$store.getters.haveTenantButton" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-wallet</v-icon></v-btn>
            <v-btn @click="refresh" elevation="0" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" fab tile class="rounded-lg mx-4 button"><v-icon>mdi-refresh</v-icon></v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col align-self="center" align="center" class="">
          <v-progress-linear v-if="$store.state.loadingAssets" indeterminate style="max-width: 720px" color="#8db9e4" class="mt-1" />
          <v-expansion-panels flat class="mt-5 px-1" style="max-width: 720px">
            <v-expansion-panel
              v-for="(asset,index) in $store.state.assets" v-bind:key="index" class="my-1 rounded-xl" v-bind:render="$store.state.render">
              <div style="position: absolute; opacity: 0.05; width: 100%; height: 100%; filter: blur(12px)">
                <v-img :src="asset.icon" width="120%" height="120%" position="center center" style="max-width: 100%; max-height: 100%; border-radius: 24px" />
              </div>
              <v-expansion-panel-header expand-icon="" @click="runApp(asset.app)">
                <div class="d-flex">
                  <div>
                    <v-img :src="asset.icon" contain width="96px" height="96px" />
                  </div>
                  <div class="ml-5 asset-startup" v-if="asset.balance === 0">
                    <span v-html="asset.startup">
                    </span>
                  </div>
                  <div class="ml-5 d-flex align-center" v-if="asset.balance > 0">
                    <div>
                      <div class="asset-balance mt-1">Balance</div>
                      <div class="asset-balance-amount mt-1">{{ asset.balance | numFormat('0') }} <span class="currency">assets</span></div>
                    </div>
                  </div>
                </div>
                <div align="right" class="mr-5">
                  <div class="asset-name mt">{{ asset.name }}</div>
                </div>
              </v-expansion-panel-header>
            </v-expansion-panel>
            <v-expansion-panel
              class="my-1 rounded-xl">
              <v-btn height="88" @click="showCreate" fab tile block plain x-large><v-icon>mdi-plus</v-icon></v-btn>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>
    </div>
</template>

<script>
import './_AssetsView.scss'
import wallet from '@/mixins/wallet'

export default {
  name: 'AssetsView',
  data () {
    return {
    }
  },
  mixins: [wallet],
  mounted () {
    this.$store.state.assetsPermit = null
    this.$store.commit('loadAssets', { router: this.$router })
  },
  watch: {
    pending: function (value) {
      if (this.$store.state.app.run) {
        return
      }
      console.log(value)
      if (value.state === 'success') {
        this.finishApprove(value)
      }
      if (value.state === 'failed') {
        this.$router.push({ name: 'main' })
      }
    },
    assetsPermit: async function (value) {
      if (value === false) {
        const request = (await this.$axios.post('/network/wallets/assets/' + this.$store.state.iD + '/approve')).data
        if (!request.permit) {
          return
        }
        const signature = (await this.$axios.post('/identification/signatures', {
          iD: this.$store.state.iD,
          requests: request
        })).data
        this.$store.state.pending = request.permit
        this.$store.state.auth = signature
        this.$store.state.loading = false
      }
    }
  },
  computed: {
    pending () {
      return this.$store.state.pending
    },
    assetsPermit () {
      return this.$store.state.assetsPermit
    }
  },
  methods: {
    runApp (app) {
      console.log(app)
      this.$store.state.app.run = app
    },
    async finishApprove (signature) {
      const receipt = await this.sendTransaction(signature.signed.permit)
      setTimeout(async () => {
        await this.waitTransaction(receipt)
        this.$store.commit('snackbar', {
          color: 'success',
          message: 'transaction_completed'
        })
        this.$store.commit('loadAssets', { router: this.$router })
      }, 1000)
    },
    showCreate () {
      this.$store.state.createAsset = true
    },
    showiD () {
      this.$store.state.qr = {
        text: this.$store.state.iD,
        logo: require('@/assets/icon-iD.png')
      }
    },
    showAssetsHistory () {
    },
    showMain () {
      this.$router.push({
        name: 'main'
      })
    },
    refresh () {
      this.$store.commit('loadAssets', { router: this.$router })
    }
  }
}
</script>
