<template>
  <div :class="{SettingView: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}">
        <div :class="{ 'font-weight-bold': true, 'title': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
          {{ $t('settings.my_settings') }}
        </div>
        <v-container fluid>
          <v-row>
            <v-col>
              <v-btn rounded large @click="showChangePIN" class="my-2" block :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">
                {{ $t('settings.change_pin') }}</v-btn>
              <v-btn @click="fingerprint" v-if="!$store.state.fingerprint && fingerprintAvailable" rounded large class="my-2" block :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">
                {{  $t('settings.enable_biometrics') }}</v-btn>
              <v-btn @click="disableFingerprint" v-if="$store.state.fingerprint && fingerprintAvailable" rounded large class="my-2" block :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">
                {{  $t('settings.disable_biometrics') }}</v-btn>
              <v-btn @click="showReset" rounded large class="my-2" block :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark" color="red">
                {{ $t('buttons.reset') }}</v-btn>
              <div class="mt-10 font-weight-bold">
                {{ $t('settings.backup') }}
              </div>
              <v-btn @click="showExport" rounded large class="my-2" block :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark" color="warning">
                {{  $t('buttons.export') }}</v-btn>
              <v-btn @click="showImport" rounded large class="my-2" block :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark" color="warning">
                {{ $t('buttons.import') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_SettingView.scss'
export default {
  name: 'SettingView',
  data () {
    return {
      changePin: null,
      pin: '',
      fingerprintAvailable: false
    }
  },
  async mounted () {
  },
  methods: {
    disableFingerprint () {
      this.$store.state.fingerprint = false
      this.$store.commit('save')
    },
    showExport () {
      this.$store.state.export = true
    },
    showImport () {
      this.$store.state.import = true
    },
    showChangePIN () {
      this.$store.state.changePin = true
    },
    showReset () {
      this.$store.state.reset = true
    }
  }
}
</script>
