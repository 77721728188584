<template>
    <div :class="{ CustomPassportComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container fluid :class="{ edit: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
          <v-row>
            <v-col>
              <v-text-field :label="$t('general.name')" outlined dark v-model="name" style="filter: drop-shadow(0px 0px 10px #00000099)"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center" style="color: white">
              {{  $t('general.layout') }}
              <v-container fluid>
                <v-row dense>
                  <v-col align-self="center" align="centers">
                    <v-carousel v-model="selected" hide-delimiters hide-delimiter-background height="200px" style="filter: drop-shadow(0px 0px 10px #00000099)">
                      <v-carousel-item v-for="(layout, index) in layouts" :key="index">
                        <v-img :src="layout.image" contain />
                      </v-carousel-item>
                    </v-carousel>
                  </v-col>
                </v-row>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn @click="save" block large rounded class="my-2" :dark="$vuetify.theme.dark" :light="!$vuetify.theme.dark">
                {{  $t('buttons.save') }}</v-btn>
              <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" color="black">
                {{  $t('buttons.cancel') }}</v-btn>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_CustomPassportComponent.scss'
export default {
  name: 'CustomPassportComponent',
  data () {
    return {
      selected: 1,
      name: '',
      layouts: [
        { id: 1, image: require('@/assets/skins/1.jpeg') },
        { id: 2, image: require('@/assets/skins/2.jpeg') },
        { id: 3, image: require('@/assets/skins/3.jpeg') },
        { id: 4, image: require('@/assets/skins/4.jpeg') },
        { id: 5, image: require('@/assets/skins/5.jpeg') },
        { id: 6, image: require('@/assets/skins/6.jpeg') },
        { id: 7, image: require('@/assets/skins/7.jpeg') },
        { id: 8, image: require('@/assets/skins/8.jpeg') },
        { id: 9, image: require('@/assets/skins/9.jpeg') },
        { id: 10, image: require('@/assets/skins/10.jpeg') },
        { id: 11, image: require('@/assets/skins/11.jpeg') },
        { id: 12, image: require('@/assets/skins/12.jpeg') },
        { id: 13, image: require('@/assets/skins/13.jpeg') },
        { id: 14, image: require('@/assets/skins/14.jpeg') },
        { id: 15, image: require('@/assets/skins/15.jpeg') },
        { id: 16, image: require('@/assets/skins/16.jpeg') },
        { id: 17, image: require('@/assets/skins/17.jpeg') },
        { id: 18, image: require('@/assets/skins/18.jpeg') },
        { id: 19, image: require('@/assets/skins/19.jpeg') },
        { id: 20, image: require('@/assets/skins/20.jpeg') },
        { id: 21, image: require('@/assets/skins/21.jpeg') },
        { id: 22, image: require('@/assets/skins/22.jpeg') },
        { id: 23, image: require('@/assets/skins/23.jpeg') },
        { id: 24, image: require('@/assets/skins/24.jpeg') },
        { id: 25, image: require('@/assets/skins/25.jpeg') }
      ]
    }
  },
  mounted () {
    this.name = this.$store.state.edit.name
    this.layouts.forEach((item, index) => {
      if (item.id === this.$store.state.edit.layout) {
        this.selected = index
      }
    })
  },
  methods: {
    save () {
      this.$store.state.loading = true
      for (const identify of this.$store.state.identities) {
        if (this.$store.state.edit.iD === identify.iD) {
          identify.name = this.name
          identify.layout = this.layouts[this.selected].id
          setTimeout(() => {
            this.$forceUpdate()
          }, 500)
          this.close()
          return
        }
      }
    },
    close () {
      this.$store.state.edit = false
      this.$store.state.loading = false
    }
  }
}
</script>
