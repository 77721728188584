<template>
  <div :class="{ ResetComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }" v-if="ready">
    <v-container fluid
                 :class="{ reset: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
      <v-row>
        <v-col align-self="center" align="center" style="color: white">
          <div :class="{ 'my-10': true }">
            <div class="title" style="color: orange">Warning</div>
            <div>
              This action is <strong>irreversible</strong>, if you do not have a backup, you will not be able to recover your assets
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="save" block large rounded class="my-2" :dark="$vuetify.theme.dark"
                 :light="!$vuetify.theme.dark" color="red">{{  $t('buttons.reset') }}
          </v-btn>
          <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark"
                 color="black">{{  $t('buttons.cancel') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_ResetComponent.scss'
export default {
  name: 'ResetComponent',
  data () {
    return {
      ready: false
    }
  },
  mounted () {
    this.$store.state.auth = true
  },
  watch: {
    authStatus: function (value) {
      if (value) {
        this.ready = true
      }
    }
  },
  computed: {
    authStatus () {
      return this.$store.state.authStatus
    }
  },
  methods: {
    save () {
      this.$store.state.loading = false
      this.$store.commit('clear')
      window.location.href = '/'
      this.close()
    },
    close () {
      this.$store.state.reset = false
      this.$store.state.loading = false
    }
  }
}
</script>
