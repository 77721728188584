import Vue from 'vue'
import VueRouter from 'vue-router'
import LoadingView from '@/views/LoadingView/LoadingView'
import HomeView from '@/views/HomeView/HomeView'
import SettingView from '@/views/HomeView/SettingView/SettingView'
import LoginView from '@/views/LoginView/LoginView'
import MainView from '@/views/HomeView/MainView/MainView'
import SignaturesView from '@/views/HomeView/SignaturesView/SignaturesView'
import SandboxView from '@/views/HomeView/SandboxView/SandboxView'
import AssetsView from '@/views/HomeView/AssetsView/AssetsView'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'loading',
    component: LoadingView
  },
  {
    path: '/welcome',
    name: 'login',
    component: LoginView
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/home/main',
        name: 'main',
        component: MainView
      },
      {
        path: '/home/assets',
        name: 'assets',
        component: AssetsView
      },
      {
        path: '/home/signatures',
        name: 'signatures',
        component: SignaturesView
      },
      {
        path: '/home/sandbox/:app',
        name: 'sandbox',
        component: SandboxView
      },
      {
        path: '/home/setting',
        name: 'setting',
        component: SettingView
      }
    ]
  }
]

const router = new VueRouter({
  routes
})

export default router
