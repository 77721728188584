<template>
    <div :class="{ SendTokenComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }">
        <v-container :class="{ edit: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
          <v-row>
            <v-col align-self="center" align="center">
              <div class="container-steps">
                <div v-if="step === 1" :class="{ 'slide-out-left': step !== 1, 'slide-in-right': step === 1, 'step': true }">
                  <div class="white--text text-uppercase mb-5">
                    Select Currency
                  </div>
                  <v-text-field v-model="filter" label="filter" outlined rounded class="my-2" dense style="max-width: 300px; font-size: 0.8rem" />
                  <div class="mt-5" style="display: flex; flex-flow: wrap; justify-content: center;">
                    <div v-for="(account, index) in show" v-bind:key="index">
                      <v-btn color="white" class="ma-3" fab x-large @click="selectToken(account)">
                        <v-img :src="account.token.logoURI.includes('http') ? account.token.logoURI : 'https://4good.network/' + account.token.logoURI" contain width="48px" height="48px" />
                      </v-btn>
                      <div class="caption">{{ account.token.symbol }}</div>
                    </div>
                  </div>
                </div>
                <div v-if="step === 2" :class="{ 'slide-out-left': step !== 2, 'slide-in-right': step === 2, 'step': true }">
                  <div class="white--text text-uppercase mb-5" v-if="$store.state.sendToken.token">
                    Send
                    <v-img class="mt-2" :src="$store.state.sendToken.token.token.logoURI.includes('http') ? $store.state.sendToken.token.token.logoURI : 'https://4good.network/' + $store.state.sendToken.token.token.logoURI" contain width="48px" height="48px" />
                  </div>
                  <v-text-field v-model="destination" label="Destination" outlined rounded class="" dense style="max-width: 500px; font-size: 0.8rem" append-icon="mdi-content-paste" append-outer-icon="mdi-qrcode" />
                  <v-text-field v-if="$store.state.sendToken.token" v-model="amount" label="Amount" outlined rounded class="" dense style="max-width: 500px; font-size: 0.8rem" :suffix="$store.state.sendToken.token.token.symbol" append-outer-icon="mdi-moon-full" @click:append-outer="maxAmount" />
                  <div class="d-flex" style="max-width: 500px; justify-content: space-between">
                    <div class="balance-container" v-if="$store.state.sendToken.token">
                      <div class="balance-name">Balance</div>
                      <span class="balance-value">{{ $store.state.sendToken.token.balance | numFormat('$ 0.00[00]')  }}</span> <span class="currency">{{ $store.state.sendToken.token.token.symbol }}</span>
                    </div>
                    <div class="cost-container">
                      <div class="cost-name">Network Cost</div>
                      <span class="cost-value">$ {{ formatApta(cost) }}</span> <span class="currency">APTA</span>
                    </div>
                  </div>
                </div>
                <div v-if="step === 3" :class="{ 'slide-out-left': step !== 3, 'slide-in-right': step === 3, 'step': true }">
                  <div class="white--text text-uppercase mb-5" v-if="$store.state.sendToken.token">
                    Send Complete
                    <v-img class="mt-2" :src="$store.state.sendToken.token.token.logoURI.includes('http') ? $store.state.sendToken.token.token.logoURI : 'https://4good.network/' + $store.state.sendToken.token.token.logoURI" contain width="48px" height="48px" />
                  </div>
                  <div class="mt-5" v-if="receipt" style="max-width: 300px">
                    <div class="receipt-container">
                      <div class="receipt-name">Transaction ID</div>
                      <div class="receipt-value">...{{ receipt.slice(-32) }}</div>
                    </div>
                    <div class="receipt-container mt-2">
                      <div class="receipt-name">Destination</div>
                      <div class="receipt-value">...{{ destination.slice(-32) }}</div>
                    </div>
                    <div class="receipt-container mt-2">
                      <div class="receipt-name">Amount</div>
                      <div class="receipt-value">{{ amount }} <span class="currency">{{ $store.state.sendToken.token.token.symbol }}</span></div>
                    </div>
                    <div class="cost-container">
                      <div class="cost-name">Network Cost</div>
                      <span class="cost-value">$ {{ formatApta(cost) }}</span> <span class="currency">APTA</span>
                    </div>
                  </div>
                </div>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align-self="center" align="center">
              <div>
                <v-btn :disabled="loading || !(amount > 0)" v-if="step === 2" class="my-5 px-10" rounded elevation="0" style="background-color: #00ab66 !important;" large @click="processTransaction">{{ $t('buttons.send') }}</v-btn>
              </div>
              <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark" color="black">
                {{ $t('buttons.close') }}</v-btn>
              <div style="max-width: 100%">
                <v-progress-linear indeterminate v-if="loading" color="white" height="10" rounded />
              </div>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
import './_SendTokenComponent.scss'
import { BigNumber, utils } from 'ethers'
import wallet from '@/mixins/wallet'
import signature from '@/mixins/signature'
export default {
  name: 'SendTokenComponent',
  data () {
    return {
      step: 1,
      filter: '',
      show: [],
      destination: '',
      amount: '',
      receipt: false,
      error: false,
      tx: {},
      loading: false,
      cost: BigNumber.from(0)
    }
  },
  watch: {
    pending: function (value) {
      if (value.state === 'success') {
        this.finishTransaction(value)
      }
      if (value.state === 'failed') {
        this.step--
      }
    },
    destination: function (value) {
      if (this.amount.length === 0) {
        return
      }
      const amount = utils.parseUnits(this.amount, this.$store.state.sendToken.token.token.decimals)
      if (amount.gt(BigNumber.from('0')) && value.length > 0) {
        this.generateTransaction(value, amount)
      }
    },
    amount: function (value) {
      if (value.length === 0) {
        return
      }
      console.log(this.$store.state.sendToken.token.token.decimals)
      const amount = utils.parseUnits(value, this.$store.state.sendToken.token.token.decimals)
      if (amount.gt(BigNumber.from('0')) && this.destination.length > 0) {
        this.generateTransaction(this.destination, amount)
      }
    },
    filter: function () {
      this.filterTokens()
    }
  },
  async mounted () {
    if (this.$store.state.sendToken.token) {
      this.nextStep()
    }
    this.filterTokens()
  },
  mixins: [wallet, signature],
  computed: {
    auth () {
      return this.$store.state.auth
    },
    pending () {
      return this.$store.state.pending
    }
  },
  methods: {
    async finishTransaction (signature) {
      this.receipt = await this.sendTransaction(signature.signed.transfer)
      setTimeout(async () => {
        const complete = await this.waitTransaction(this.receipt)
        console.log(complete)
        this.$store.commit('snackbar', {
          color: 'success',
          message: 'transaction_completed'
        })
        this.$store.commit('load')
      }, 1000)
      this.nextStep()
      this.$store.state.loading = false
    },
    async processTransaction () {
      this.$store.state.loading = true
      const request = {
        transfer: this.tx
      }
      request.transfer.payAmount = utils.parseUnits(this.amount, this.$store.state.sendToken.token.token.decimals)
      request.transfer.payToken = this.$store.state.sendToken.token.token.symbol
      request.transfer.destination = '...' + this.destination.slice(-16)
      if (this.$store.state.signer) {
        const tx = this.filterAllowTags(request.transfer)
        const signer = this.$store.state.signer
        console.log(this.$store.state.mode)
        if (this.$store.state.mode === 'Ledger') {
          this.$store.commit('requestLedgerSign', { request: request, pending: request.transfer })
        }
        try {
          const signedTx = await signer.signTransaction(this.parseBigNumber(tx))
          const signature = {
            signed: {
              transfer: signedTx
            }
          }
          this.$store.state.auth = false
          this.finishTransaction(signature)
        } catch (e) {
          console.log(e.toString())
          this.$store.state.auth = false
          this.$store.state.loading = false
          this.step--
        }
        return
      }
      await this.$store.commit('requestSign', { request: request, pending: request.transfer })
      /*      const signature = (await this.$axios.post('/identification/signatures', {
        iD: this.$store.state.iD,
        requests: request
      })).data
      this.$store.state.pending = request.transfer
      this.$store.state.auth = signature */
      this.$store.state.loading = false
    },
    maxAmount () {
      this.amount = this.$store.state.sendToken.token.balance
    },
    async generateTransaction (to, amount) {
      this.error = false
      this.loading = true
      try {
        console.log(this.$store.state.sendToken.token)
        const from = this.$store.state.sendToken.token.address
        this.tx = await this.getTransaction(from, to, amount, !this.$store.state.sendToken.token.native ? this.$store.state.sendToken.token.token.address : false)
        this.cost = BigNumber.from(await this.estimateTransaction(this.tx))
        console.log(utils.formatEther(this.tx.value))
      } catch (e) {
        this.error = e.response.data.error
      }
      this.loading = false
    },
    selectToken (account) {
      this.$store.state.sendToken.token = account
      this.nextStep()
    },
    filterTokens () {
      const tokens = this.$store.state.accounts
      if (this.filter.length < 3) {
        this.show = tokens.slice(0, 8)
        return
      }
      this.show = tokens.filter(account => account.token.name.toLowerCase().includes(this.filter.toLowerCase())).slice(0, 8)
    },
    nextStep () {
      this.step++
    },
    close () {
      this.$store.state.sendToken = false
      this.$store.state.loading = false
    }
  }
}
</script>
