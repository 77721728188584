'use strict'

import { ethers } from 'ethers'
import Eth from '@ledgerhq/hw-app-eth'
import TransportWebHID from '@ledgerhq/hw-transport-webhid'

function waiter (duration) {
  return new Promise((resolve) => {
    setTimeout(resolve, duration)
  })
}

export class LedgerSigner extends ethers.Signer {
  _eth
  path = "m/44'/161803'/0'/0/0"
  constructor () {
    super()

    ethers.utils.defineReadOnly(this, '_eth', TransportWebHID.create().then((transport) => {
      const eth = new Eth(transport)
      return eth.getAppConfiguration().then((config) => {
        return eth
      }, (error) => {
        return Promise.reject(error)
      })
    }, (error) => {
      return Promise.reject(error)
    }))
  }

  _retry (callback, timeout) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      if (timeout && timeout > 0) {
        setTimeout(() => {
          reject(new Error('timeout'))
        }, timeout)
      }

      const eth = await this._eth

      // Wait up to 5 seconds
      for (let i = 0; i < 50; i++) {
        try {
          const result = await callback(eth)
          return resolve(result)
        } catch (error) {
          if (error.id !== 'TransportLocked') {
            return reject(error)
          }
        }
        await waiter(100)
      }

      return reject(new Error('timeout'))
    })
  }

  async getAddress () {
    const account = await this._retry((eth) => eth.getAddress(this.path))
    return ethers.utils.getAddress(account.address)
  }

  async signMessage (message) {
    if (typeof (message) === 'string') {
      message = ethers.utils.toUtf8Bytes(message)
    }

    const messageHex = ethers.utils.hexlify(message).substring(2)

    const sig = await this._retry((eth) => eth.signPersonalMessage(this.path, messageHex))
    sig.r = '0x' + sig.r
    sig.s = '0x' + sig.s
    return ethers.utils.joinSignature(sig)
  }

  async signTransaction (transaction) {
    const tx = await ethers.utils.resolveProperties(transaction)
    const baseTx = {
      chainId: (tx.chainId || undefined),
      data: (tx.data || undefined),
      gasLimit: (tx.gasLimit || undefined),
      gasPrice: (tx.gasPrice || undefined),
      nonce: (tx.nonce ? ethers.BigNumber.from(tx.nonce).toNumber() : undefined),
      to: (tx.to || undefined),
      value: (tx.value || undefined)
    }

    const unsignedTx = ethers.utils.serializeTransaction(baseTx).substring(2)
    // const resolution = await ledgerService.resolveTransaction(unsignedTx)
    const sig = await this._retry((eth) => eth.signTransaction(this.path, unsignedTx))

    return ethers.utils.serializeTransaction(baseTx, {
      v: ethers.BigNumber.from('0x' + sig.v).toNumber(),
      r: ('0x' + sig.r),
      s: ('0x' + sig.s)
    })
  }
}
