import moment from 'moment'

export default {
  data () {
    return {
    }
  },
  methods: {
    getFormatDate (unix) {
      if (typeof unix === 'number') {
        const date = moment(unix * 1000).locale(this.$i18n.locale)
        return date.format('L LT')
      } else {
        const date = moment(unix, 'LLLL').locale(this.$i18n.locale)
        return date.format('L LT')
      }
    }
  }
}
