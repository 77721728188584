import { utils } from 'ethers'

export default {
  data () {
    return {
    }
  },
  computed: {
  },
  methods: {
    async getHistory (iD, more = 0) {
      return (await this.$axios.get('/network/addresses/' + iD + '/transactions', {
        params: {
          more: more
        }
      })).data
    },
    async registerNotification (iD, token) {
      const [version, hash] = iD.split('<')
      console.log('notify', version + '<' + hash)
      return (await this.$axios.post('/network/wallets/notifications', {
        iD: hash,
        token: token
      })).data
    },
    async getTransaction (from, to, amount, contract) {
      return (await this.$axios.post('/network/wallets/transactions/' + (contract || ''), {
        from: from,
        to: to,
        amount: amount
      })).data
    },
    async estimateTransaction (unsignedTx) {
      return (await this.$axios.put('/network/transactions/estimate', {
        unsignedTx: unsignedTx
      })).data
    },
    async sendTransaction (signedTx) {
      return (await this.$axios.put('/network/transactions', {
        signedTx: signedTx
      })).data
    },
    async waitTransaction (hash) {
      return (await this.$axios.get('/network/transactions/' + hash + '/wait')).data
    },
    formatApta (value) {
      return utils.formatEther(value)
    }
  }
}
