<template>
  <div :class="{ ChangePinComponent: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark }" v-if="ready">
    <v-container fluid
                 :class="{ change: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'py-10': true }">
      <v-row>
        <v-col align-self="center" align="center" style="color: white">
          <div :class="{ 'my-10': true }" v-if="step === 1">
            <div>
              {{ $t('pin.new_pin') }}
            </div>
            <pin-code-input
              v-model="pin"
              :length="6"
              placeholder="•"
              :secure="true"
              class="my-2"
            />
          </div>
          <div :class="{ 'my-10': true }" v-if="step === 2">
            <div>
              {{ $t('welcome.confirm_pin') }}
            </div>
            <pin-code-input
              v-model="repin"
              :length="6"
              placeholder="•"
              :secure="true"
              class="my-2"
            />
            <div v-if="pin !== repin && repin.length === 6" class="nomatch">
              PIN code does not match {{ $t('message.not_match_pin') }}
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn @click="step++" block large rounded class="my-2" :dark="$vuetify.theme.dark"
                 :light="!$vuetify.theme.dark" :disabled="pin.length < 6" v-if="step === 1">{{ $t('buttons.continue') }}
          </v-btn>
          <v-btn @click="save" block large rounded class="my-2" :dark="$vuetify.theme.dark"
                 :light="!$vuetify.theme.dark" :disabled="repin !== pin" v-if="step === 2">{{ $t('buttons.save') }}
          </v-btn>
          <v-btn @click="close" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark"
                 color="black" v-if="step === 1">{{ $t('buttons.cancel') }}
          </v-btn>
          <v-btn @click="step--" block rounded class="my-2" :dark="!$vuetify.theme.dark" :light="$vuetify.theme.dark"
                 color="black" v-if="step === 2">{{ $t('buttons.back') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import './_ChangePinComponent.scss'

export default {
  name: 'ChangePinComponent',
  data () {
    return {
      step: 1,
      pin: '',
      repin: '',
      ready: false
    }
  },
  mounted () {
    this.$store.state.auth = true
  },
  watch: {
    authStatus: function (value) {
      if (value) {
        this.ready = true
      }
    }
  },
  computed: {
    authStatus () {
      return this.$store.state.authStatus
    }
  },
  methods: {
    save () {
      this.$store.state.loading = false
      this.$store.state.pin = this.pin
      this.$store.commit('save')
      this.close()
    },
    close () {
      this.$store.state.changePin = false
      this.$store.state.loading = false
    }
  }
}
</script>
