<template>
    <div :class="{ 'LoginView': true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark, 'slide-out-bck-center': next }">
      <v-container fluid class="fill-height">
        <v-row>
          <v-col align-self="center" align="center">
            <div class="slide-in-bottom overline" style="animation-delay: 0s">
              <v-img max-width="300px" width="50%" :src="require('@/assets/logo_light.svg')"  />
            </div>
          </v-col>
        </v-row>
        <v-row v-if="$vuetify.breakpoint.height > 720">
          <v-col align-self="center" align="center">
            <div class="slide-in-bottom overline" style="animation-delay: 1s">
              <v-carousel hide-delimiter-background hide-delimiters :show-arrows="false" height="50px" cycle continuous interval="5000" reverse-transition="new-transition" transition="new-transition">
                <v-carousel-item v-for="(item, index) in welcome" v-bind:key="index">
                  <div class="welcome heartbeat">
                    {{ item }}
                  </div>
                </v-carousel-item>
              </v-carousel>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div v-if="!success">
              <div>
                <v-img class="qr" v-if="qr" :src="qr" width="250px" height="250px" aspect-ratio="1" style="border-radius: 16px" contain />
              </div>
              <v-progress-circular v-if="!qr" indeterminate width="20" color="white" size="250" />
              <div v-if="qr && isMobile()" class="icon-mobile mt-5 cursor-pointer" @click="openApp">
                <v-img v-if="$device.ios" :src="require('@/assets/icon-ios.png')" contain width="32px" height="32px"></v-img>
                <v-img v-if="$device.android" :src="require('@/assets/icon-android.png')" contain width="32px" height="32px"></v-img>
              </div>
              <div class="caption mt-1" v-if="qr && isMobile()">
                Click to open <strong>iD4Good</strong>
              </div>
            </div>
            <transition name="fade">
              <div v-if="success">
                <v-btn fab class="fingerprint heartbeat" height="128px" width="128px" color="green">
                  <div :class="{fingerprint: true, wave: true, ping: true, 'theme--light': !$vuetify.theme.dark, 'theme--dark': $vuetify.theme.dark}"></div>
                  <v-icon color="white" size="96px">mdi-emoticon-happy-outline</v-icon>
                </v-btn>
              </div>
            </transition>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="overline">
              {{  $t('login.sign_alternatives') }}
            </div>
            <div class="d-flex" v-if="!$vuetify.theme.dark" style="max-width: 350px">
              <v-img @click="loginLedger" class="mx-5" :src="require('@/assets/ledger_dark.svg')" contain height="40px" width="120px" aspect-ratio="1" style="cursor: pointer" />
              <v-img @click="loginMetamask" class="mx-5" :src="require('@/assets/metamask_dark.svg')" contain height="40px" width="120px" aspect-ratio="1" style="cursor: pointer" />
            </div>
            <div class="d-flex" v-if="$vuetify.theme.dark" style="max-width: 350px">
              <v-img @click="loginLedger" class="mx-5" :src="require('@/assets/ledger_light.svg')" contain height="40px" width="120px" aspect-ratio="1" style="cursor: pointer" />
              <v-img @click="loginMetamask" class="mx-5" :src="require('@/assets/metamask_light.svg')" contain height="40px" width="120px" aspect-ratio="1" style="cursor: pointer" />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align-self="center" align="center">
            <div class="mb-5">
              <v-btn rounded small>
                {{ $t('general.need_help') }}
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <transition name="fade">
        <AuthenticationComponent v-if="$store.state.auth" />
      </transition>
    </div>
</template>

<script>

import './_LoginView.scss'
import { ethers, Wallet } from 'ethers'
import api from '@/mixins/api'
import { LedgerSigner } from '@/libs/LedgerSigner'
import AuthenticationComponent from '@/components/AuthenticationComponent/AuthenticationComponent.vue'

export default {
  name: 'LoginView',
  components: { AuthenticationComponent },
  data () {
    return {
      next: false,
      pin: '',
      welcome: ['Welcome', 'Bienvenido', 'Bienvenue', 'Benvenuto', 'Välkommen', 'Hoş geldin', 'ようこそ', '欢迎'],
      error: false,
      qr: null,
      success: false,
      token: null,
      hash: null,
      links: {}
    }
  },
  mounted () {
    this.getChallenge()
  },
  mixins: [api],
  methods: {
    openApp () {
      if (this.$device.ios) {
        window.location.href = this.links.ios
      }
      if (this.$device.android) {
        window.location.href = this.links.android
      }
    },
    async getChallenge () {
      this.$store.state.mode = 'iD4Good'
      const preLogin = this.$store.state.preLogin
      console.log(preLogin)
      const result = preLogin ? await this.$axios.get('/identification/' + preLogin) : await this.$axios.post('/identification')
      const challenge = result.data
      this.token = challenge.token
      this.hash = challenge.hash
      if (challenge) {
        if (!preLogin) {
          this.qr = challenge.qr
          this.links = challenge.links
        }
        try {
          let wait = await this.$axios.get('/identification/' + challenge.hash + '/wait')
          if (wait.data.state === 'scanning' || wait.data.state === 'waiting') {
            this.qr = null
            wait = await this.$axios.get('/identification/' + challenge.hash + '/wait')
          }
          if (wait.data.state === 'success') {
            this.success = true
            console.log(wait.data)
            this.$store.state.iD = wait.data.data.iD
            setTimeout(async () => {
              this.next = true
              this.$store.state.session = (await this.$axios.post('/identification/' + challenge.hash + '/session')).data
            }, 1800)
            if (this.$store.state.preApp) {
              setTimeout(() => {
                this.$router.push({ name: 'assets' })
              }, 3200)
              return
            }
            setTimeout(() => {
              this.$router.push({ name: 'main' })
            }, 3200)
            // Redirect
          }
        } catch (e) {
          this.$store.state.preLogin = false
          if (this.$store.state.iD) {
            return
          }
          this.qr = null
          this.getChallenge()
        }
      }
    },
    isMobile () {
      return this.$device.mobile
    },
    async loginLedger () {
      try {
        this.$store.state.mode = 'Ledger'
        const signer = await new LedgerSigner()
        this.$store.commit('requestLedgerSign', { request: {}, pending: {} })
        const signedTx = await signer.signMessage(this.token)
        const iD = await this.encodeAddress(await signer.getAddress())
        await this.putChallenge(iD, this.hash, signedTx, true)
        this.$store.state.auth = false
        this.$store.state.signer = signer
      } catch (e) {
      }
    },
    async loginMetamask () {
      this.qr = null
      try {
        this.$store.state.mode = 'Metamask'
        const provider = new ethers.providers.Web3Provider(window.ethereum, 'any')
        await provider.send('eth_requestAccounts', [])
        const signer = provider.getSigner()
        const seed = await signer.signMessage('Wallet4good Sign')
        const wallet = await this.recoveryWallet(seed.substring(0, 66))
        this.$store.state.iD = wallet.address
        this.$store.state.signer = new Wallet(wallet.privateKey)
        console.log(await this.$store.state.signer.getAddress())
        this.success = true
        setTimeout(() => {
          this.next = true
        }, 1000)
        setTimeout(() => {
          this.$router.push({ name: 'main' })
        }, 1500)
      } catch (e) {
        console.log(e)
        this.qr = null
        this.getChallenge()
      }
    }
  }
}
</script>
